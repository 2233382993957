import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiResponse, IApiReceiveResponse, IStyle, IParams} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StyleService {
    constructor(private apiService: ApiService) {
    }

    getStyles(params: IParams): Observable<IApiReceiveResponse<IStyle>> {
        return this.apiService.get<IApiReceiveResponse<IStyle>>('styles/receive/all', {params}, true).pipe(
            map(({results, count}) => {
                if (params.categoryId) {
                    results = results.map((style) => {
                        if (style.categoryStyles && style.categoryStyles.length) {
                            style.categoryStyleId = style.categoryStyles[0].categoryStyleId;
                        }
                        delete style.categoryStyles;
                        return style;
                    });
                }

                return {results, count};
            })
        );
    }

    getStyle(styleId: string): Observable<IStyle> {
        return this.apiService.get<IStyle>(`styles/receive/${styleId}`, {}, true);
    }

    addStyle(style: IStyle): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`styles/add`, style, {}, true, true);
    }

    updateStyle(styleId: string, style: IStyle): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`styles/update/${styleId}`, style, {}, true, true);
    }

    deleteStyle(styleId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`styles/delete/${styleId}`, {}, true, true);
    }

    getCategoryStyles(params: IParams): Observable<IApiReceiveResponse<IStyle>> {
        return this.apiService.get<IApiReceiveResponse<IStyle>>('styles/category-style/receive/all', {params}, true)
            .pipe(map(({results, count}) => {
                results = results.map((style) => {
                    style.name = style.style.name;
                    delete style.style;
                    return style;
                });
                return {results, count};
            }));
    }

    getCategoryStyle(categoryStyleId: string): Observable<IStyle> {
        return this.apiService.get<IStyle>(`styles/category-style/receive/${categoryStyleId}`, {}, true);
    }

    addCategoryStyle(categoryId: string, styleId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`styles/category-style/add/${categoryId}/${styleId}`, {}, {}, true, true);
    }

    updateCategoryStyle(categoryStyleId: string, order: number): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`styles/category-style/update/${categoryStyleId}`, {order}, {}, true, true);
    }

    deleteCategoryStyle(categoryStyleId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`styles/category-style/delete/${categoryStyleId}`, {}, true, true);
    }
}
