import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AdminService, SharedService} from '../services';

const permissions: any = {
    '/artworks/list': 'canReadArtwork',
    '/artworks/artwork/:artworkId': 'canReadArtwork',
    '/artworks/update/:artworkId': 'canUpdateArtwork',
    '/categories/category': 'canReadCategory',
    '/categories/ordering': 'canReadOrdering',
    '/categories/style': 'canReadStyle',
    '/categories/subject': 'canReadSubject',
    '/categories/medium': 'canReadMedium',
    '/categories/material': 'canReadMaterial',
    '/categories/color': 'canReadColor',
    '/customers/list': 'canReadUser',
    '/artists/list': 'canReadUser',
    '/artists/update-profile/:artistId': 'canReadUser',
    '/artists/artist/:artistId': 'canReadUser',
    '/admins/list': 'canReadAdmin',
    '/settings/': 'canReadSetting',
    '/artist-review/admin/list': 'canReview',
    '/artist-review/admin/review/:artistId': 'canReview',
    '/artist-review/jury/list': 'canVote',
    '/artist-review/jury/review/:artistId': 'canVote',
    '/artwork-review/list': 'canReview',
    '/artwork-review/:artistId/:artworkId': 'canReview',
    '/offers/list': 'canReadOffer',
    '/offers/artwork/:artworkId': 'canReviewOffer',
    '/settings': 'canUpdateSetting',
    'orders/list': 'canReadOrder',
    'gift-cards/list': 'canReadOrder',
    '/our-team/list': 'canReadOrder',
};

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    constructor(private adminService: AdminService,
                private sharedService: SharedService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return new Promise<boolean | UrlTree>((resolve, reject) => {
            const url = `/${state.url.split('/')[1]}/${route.routeConfig.path}`;
            const permission = permissions[url];
            this.adminService
                .getProfile()
                .subscribe((admin) => {
                    if (url === '/dashboard/') {
                        if (admin.role.canVote) {
                            resolve(false);
                            this.router.navigate(['/home/permission-denied']);
                        } else {
                            resolve(true);
                        }
                    } else if (!admin.role[permission]) {
                        resolve(false);
                        this.router.navigate(['/home/permission-denied']);
                    } else {
                        resolve(true);
                    }
                }, (err) => {
                    if (err.status === 401) {
                        this.sharedService.removeToken();
                        this.router.navigate(['/auth/login']);
                    }
                    reject(err);
                });
            resolve(true);
        });
    }
}
