<div>
    <form name="educationForm" [formGroup]="educationForm" novalidate>
        <mat-divider *ngIf="educationIndex"></mat-divider>
        <div fxLayout="row">
            <span fxFlex=""></span>
            <div class="button-underline-right">
                <button class="button button-2 text-green submit-button" (click)="removeEducation()" type="button"
                        fxFlexAlign="end end">Remove
                </button>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <input matInput formControlName="name" placeholder="Name" required>
                <mat-error *ngIf="name.hasError('required')">{{ 'education_name_required' | text }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <mat-label>From year</mat-label>
                <mat-select [formControl]="startDate" required>
                    <mat-option *ngFor="let startDate of startDates" [value]="startDate">
                        {{startDate}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="startDate.hasError('required')">{{ 'education_from_year_required' | text }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <mat-label>To year</mat-label>
                <mat-select [formControl]="endDate" required [disabled]="!startDate.value">
                    <mat-option *ngFor="let endDate of endDates" [value]="endDate" [disabled]="startDate.value && endDate <= startDate.value">
                        {{endDate}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="endDate.hasError('required')">{{ 'education_to_year_required' | text }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <input matInput formControlName="specialization" placeholder="Specialization" required>
                <mat-error *ngIf="specialization.hasError('required')">{{ 'education_specialization_required' | text }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <input matInput formControlName="city" placeholder="City" required>
                <mat-error *ngIf="city.hasError('required')">{{ 'city_required' | text }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <input matInput formControlName="country" placeholder="Country" required>
                <mat-error *ngIf="country.hasError('required')">{{ 'country_required' | text }}</mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
