import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {IParams} from '../../interfaces';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {
    @Input() count: number;
    @Input() pageSizeOptions = [20, 40, 60, 80, 100];
    params: IParams = {};
    private subscriptions: Subscription[] = [];

    constructor(private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        const routeChange = this.route.queryParams.subscribe((params) => {
            this.params = {...params};
            this.params.offset = +this.params.offset;
            this.params.limit = +this.params.limit;
        });
        this.subscriptions.push(routeChange);
    }

    pageChanged(event: any): void {
        this.params.offset = event.pageIndex * event.pageSize;
        this.params.limit = event.pageSize;
        this.navigate();
    }

    navigate() {
        this.router.navigate([this.router.url.split('?')[0]], {
            queryParams: this.params
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
