import {Component, Input, OnInit} from '@angular/core';
import {IAdmin} from '../../interfaces';
import {MatDrawer} from '@angular/material/sidenav';
import {Router} from '@angular/router';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
    @Input() drawer: MatDrawer;
    @Input() tabletMediaMatcher: MediaQueryList;
    @Input() admin: IAdmin;
    menuList: any[] = [];

    constructor(public router: Router) {
    }

    ngOnInit(): void {
        this.menuList.push({
            name: 'Dashboard',
            url: '/dashboard',
            params: {},
            access: !this.admin.role.canVote
        });
        this.menuList.push({
            name: 'Categories',
            url: '/categories',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadCategory ||
                this.admin.role.canReadStyle ||
                this.admin.role.canReadSubject ||
                this.admin.role.canReadMedium ||
                this.admin.role.canReadMaterial ||
                this.admin.role.canReadColor ||
                this.admin.role.canReadOrdering
        });
        this.menuList.push({
            name: 'Shipping',
            url: '/shipping/country',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadCountry ||
                this.admin.role.canReadShippingZone ||
                this.admin.role.canReadShippingZonePrice
        });
        if (this.admin.role.roleName === 'admin') {
            this.menuList.push({
                name: 'Artist Review',
                url: '/artist-review/admin/list',
                params: {offset: 0, limit: 20},
                access: this.admin.role.canReview
            });
        }

        if (this.admin.role.roleName === 'jury') {
            this.menuList.push({
                name: 'Artist Review',
                url: '/artist-review/jury/list',
                params: {offset: 0, limit: 20},
                access: this.admin.role.canVote
            });
        }
        this.menuList.push({
            name: 'Artwork Review',
            url: '/artwork-review/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReview
        });
        this.menuList.push({
            name: 'Artists',
            url: '/artists/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadUser
        });
        this.menuList.push({
            name: 'Artworks',
            url: '/artworks/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadArtwork
        });
        this.menuList.push({
            name: 'Artwork Offers',
            url: '/offers/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReviewOffer
        });
        this.menuList.push({
            name: 'Customers',
            url: '/customers/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadUser
        });
        this.menuList.push({
            name: 'Administrators',
            url: '/admins/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadAdmin
        });
        this.menuList.push({
            name: 'Orders',
            url: '/orders/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadOrder
        });
        this.menuList.push({
            name: 'Donations',
            url: '/donations/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadOrder
        });
        this.menuList.push({
            name: 'Donations Made',
            url: '/donations/made',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadOrder
        });
        this.menuList.push({
            name: 'Gift Cards',
            url: '/gift-cards/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadOrder
        });
        this.menuList.push({
            name: 'Add Gift Cards',
            url: '/gift-cards/add',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadOrder
        });
        this.menuList.push({
            name: 'Promo Codes',
            url: '/promo-codes/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadPromoCode
        });
        this.menuList.push({
            name: 'Our Team',
            url: '/our-team/list',
            params: {},
            access: this.admin.role.canReadOrder
        });
        this.menuList.push({
            name: 'MOURADICK ART CENTER',
            url: '/art-center/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadOrder
        });
        this.menuList.push({
            name: 'Filtered Links',
            url: '/filtered-links/list',
            params: {offset: 0, limit: 20},
            access: this.admin.role.canReadArtwork
        });
        this.menuList.push({
            name: 'Settings',
            url: '/settings',
            params: {},
            access: this.admin.role.canReadSetting
        });
    }
}
