import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IArtwork} from '../interfaces';
import {ArtworkService} from '../services';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ArtworkResolverService implements Resolve<IArtwork> {

    constructor(private artworkService: ArtworkService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IArtwork> {
        const artworkId = route.params.artworkId;
        return this.artworkService.getArtworkForAdmin(artworkId);
    }
}
