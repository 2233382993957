<div fxLayout="column">
    <div class="item-row" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="24px" [fxLayoutGap]="gap">
        <div class="artwork-image" fxFlexAlign="end" fxFlexAlign.xs="start"
             [ngStyle]="{width: imageWidth, height: imageHeight }">
            <img class="object-cover" appPictureLoad [src]="artwork.pictures[0].small | picture" [width]="imageWidth"
                 [height]="imageHeight">
        </div>
        <div class="artwork description" fxLayout="column" fxFlex>
            <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign.gt-xs="end start" fxLayoutGap.gt-xs="90px"
                 fxLayoutGap.xs="0">
                <div *ngIf="removeButton" class="button-underline-right" fxFlex fxFlexOrder.xs="2"
                     fxFlexAlign.gt-xs="end"
                     fxFlexAlign.xs="start">
                    <button type="button" class="subtitle subtitle-2 text-green submit-button"
                            (click)="removeCartItem(artwork)">REMOVE
                    </button>
                </div>
                <span *ngIf="!removeButton && bigStyle" fxHide.xs fxFlex></span>
                <div fxFlexOrder.xs="1" *ngIf="bigStyle">
                    <a [routerLink]="['/artwork/' + artwork.artworkId]">
                        <div class="subtitle subtitle-1">{{artwork.name}}</div>
                    </a>
                    <span class="body-2 text-light">
                    {{ artwork.width | dimensionUnit: (admin?.dimensionUnit || 'inch') | number:  '1.1-1' }} W x
                        {{ artwork.height | dimensionUnit: (admin?.dimensionUnit || 'inch') | number:  '1.1-1' }} H x
                        {{ artwork.depth | dimensionUnit: (admin?.dimensionUnit || 'inch') | number:  '1.1-1' }} D
                    <ng-container *ngIf="!admin">in</ng-container>
                    <ng-container *ngIf="admin">{{ admin.dimensionUnit === 'inch' ? 'in' : 'cm' }}</ng-container>
                </span>
                </div>
            </div>
            <div fxLayout="row wrap" fxFlex fxFlexOrder.xs="1">
                <div fxFlex="100" fxLayout="column" fxLayoutAlign="end start" fxLayoutGap="8px">
                    <a [routerLink]="['/artwork/' + artwork.artworkId]" *ngIf="smallStyle">
                        <div class="subtitle subtitle-1">{{artwork.name}}</div>
                    </a>
                    <div>
                        <a [routerLink]="['/artist-artwork-list/' + artwork.artist.artistId]"
                           [queryParams]="{ offset: 0, limit: 20 }">
                            <div [ngClass]="smallStyle ? 'caption' : 'subtitle subtitle-1'">
                                {{ artwork.artist.user.firstName }} {{ artwork.artist.user.lastName }}
                            </div>
                        </a>
                        <span *ngIf="artwork.artist.user.city || artwork.artist.user.country" class="body-2 text-gray">
                        {{artwork.artist.user.city}}, {{ artwork.artist.user.country }}
                    </span>
                    </div>
                </div>
                <div *ngIf="bigStyle" fxLayout="row" fxFlex="100" fxLayoutAlign="start end">
                    <span class="subtitle subtitle-3">{{ artwork.category.name }}</span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-1 text-green"
                          fxFlexAlign="end">${{artwork.completedPrice | number: '1.0-2'}}</span>
                </div>
                <div *ngIf="shippingDetail" fxLayout="row" fxFlex="100" fxLayoutAlign="start end">
                    <span class="body-2 text-gray text-italic">The item usually ships within 5-10 business days</span>
                </div>
            </div>
        </div>
        <div *ngIf="smallStyle" fxLayout="row" class="pt-1" fxFlex="100" fxLayoutAlign="start end">
            <span class="subtitle subtitle-3">{{ artwork.category.name }}</span>
            <span fxFlex></span>
            <span class="subtitle subtitle-3 text-green" fxFlexAlign="end">${{artwork.completedPrice | number: '1.0-2'}}</span>
        </div>
        <div *ngIf="offerStyle" class="pt-2" fxLayout="column" fxFlex="100" fxLayoutGap="20px">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="start end">
                <span class="subtitle subtitle-3">OFFER PRICE</span>
                <span fxFlex></span>
                <span class="subtitle subtitle-1 text-green">{{ artwork.completedPrice }}</span>
            </div>
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="end start">
                <div class="button-underline-left">
                    <button class="subtitle subtitle-2 text-green submit-button">increase bid/re-bid</button>
                </div>
                <span fxFlex></span>
                <span class="subtitle subtitle-3 text-red" fxFlexAlign="center">{{ artwork.status | uppercase }}</span>
            </div>
        </div>
    </div>
    <div fxLayout="row" style="margin-top: 50px;" fxFlex="100" fxLayoutAlign="end start">
        <span class="subtitle subtitle-3">TOTAL TO BE PAID TO ARTIST</span>
        <div class="tooltip" data-direction="bottom">
            <div class="tooltip__initiator">
                <!-- element with tooltip hover call -->
                <img src="../../../../assets/icons/info-icon.svg">
            </div>
            <div class="tooltip__item" fxLayout="column" fxLayoutGap="15px">
                <h6 class="pb-2">PAYMENT BREAKDOWN</h6>
                <div fxLayout="row">
                    <span class="subtitle subtitle-3">TOTAL TRANSACTION AMOUNT</span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-3 text-green" fxFlexAlign="center">${{ artwork.soldPrice | number: '1.0-2' }}</span>
                </div>
                <div fxLayout="row">
                                    <span class="subtitle subtitle-3">
                                        packaging, insurance, shipping
                                    </span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-3 text-green"
                          fxFlexAlign="center">-${{ artwork.shippingPrice + artwork.insurancePrice | number: '1.2-2' }}</span>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="row">
                                    <span class="subtitle subtitle-3">SALES PRICE OF ART <span
                                        class="text-green">(100%)</span></span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-3 text-green" fxFlexAlign="center">
                                        ${{ (artwork.soldPrice - artwork.insurancePrice - artwork.shippingPrice) | number: '1.2-2' }}
                                    </span>
                </div>
                <div fxLayout="row">
                                    <span class="subtitle subtitle-3">WEBSITE OPERATIONAL COSTS<span
                                        class="text-green">(10%)</span></span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-3 text-green" fxFlexAlign="center">
                                        ${{ (((artwork.soldPrice - artwork.insurancePrice - artwork.shippingPrice) / 100) * 10) | number: '1.2-2' }}
                                    </span>
                </div>
                <div fxLayout="row">
                                    <span class="subtitle subtitle-3">FINANCIAL TRANSACTION COSTS <span
                                        class="text-green">(5%)</span></span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-3 text-green" fxFlexAlign="center">
                                        ${{ (((artwork.soldPrice - artwork.insurancePrice - artwork.shippingPrice) / 100) * 5) | number: '1.2-2' }}
                                    </span>
                </div>
                <div fxLayout="row">
                                    <span class="subtitle subtitle-3">CHARITIES IN Armenia <span
                                        class="text-green">(42.5%)</span></span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-3 text-green" fxFlexAlign="center">
                                        ${{ (((artwork.soldPrice - (artwork.insurancePrice + artwork.shippingPrice)) / 100) * 42.5) | number: '1.2-2' }}
                                    </span>
                </div>
                <mat-divider></mat-divider>
                <div fxLayout="row">
                                    <span class="subtitle subtitle-3">NET DUE TO ARMENIAN ARTIST <span
                                        class="text-green">(42.5%)</span></span>
                    <span fxFlex></span>
                    <span class="subtitle subtitle-3 text-green" fxFlexAlign="center">
                                        ${{ (((artwork.soldPrice - artwork.insurancePrice - artwork.shippingPrice) / 100) * 42.5) | number: '1.2-2' }}
                                    </span>
                </div>
            </div>
        </div>
        <span fxFlex></span>
        <span class="subtitle subtitle-3 text-green" fxFlexAlign="center">
                            ${{ (((artwork.soldPrice - artwork.insurancePrice - artwork.shippingPrice) / 100) * 42.5) | number: '1.0-0' }}
                        </span>
    </div>
    <mat-divider [ngStyle]="{marginTop: marginTop, marginBottom: marginBottom}"></mat-divider>
</div>
