import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiResponse, IApiReceiveResponse, ICategory} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    constructor(private apiService: ApiService) {
    }

    getCategories(params: any): Observable<IApiReceiveResponse<ICategory>> {
        return this.apiService.get<IApiReceiveResponse<ICategory>>('categories/receive/all', {params}, true);
    }

    getCategory(categoryId: string): Observable<ICategory> {
        return this.apiService.get<ICategory>(`categories/receive/${categoryId}`, {}, true);
    }

    addCategory(category: ICategory): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`categories/add`, category, {}, true, true);
    }

    updateCategory(categoryId: string, category: ICategory): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`categories/update/${categoryId}`, category, {}, true, true);
    }

    deleteCategory(categoryId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`categories/delete/${categoryId}`, {}, true, true);
    }
}
