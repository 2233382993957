<div fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
    <div fxFlex.gt-sm="50" fxFlexOrder.lt-sm="2">
        <h6>{{ artist.user.firstName }} {{ artist.user.lastName }}</h6>
        <p>
            <span class="text-green text-strong">Email:</span>
            <span class="value">{{ artist.user.email }}</span>
        </p>
        <p>
            <span class="text-green text-strong">Phone:</span>
            <span class="value">{{ artist.user.phone }}</span>
        </p>
        <p>
            <span class="text-green text-strong">Address:</span>
            <span class="value">{{artist.user.address}} {{ artist.user.city }}, {{ artist.user.state }} , {{ artist.user.country }}</span>
        </p>
        <p>
            <span class="text-green text-strong">Pseudonym:</span>
            <span class="value">{{ artist.pseudonym | titlecase }}</span>
        </p>
        <p>
            <span class="text-green text-strong">Specialization:</span>
            <span class="value" *ngFor="let specialization of artist.specializations">{{ specialization.name | titlecase}}</span>
        </p>
        <p>
            <span class="text-green text-strong">Date of Birth: </span>
            <span class="value">{{ artist.dateOfBirth | date: 'd/MM/y' }}</span>
        </p>
        <h6 class="text-green">About</h6>
        <p *ngIf="!artist.about">No data</p>
        <div [innerHTML]="safeHtml"></div>
        <h6 class="text-green">From The Artist</h6>
        <p *ngIf="!artist.fromTheArtist">No data</p>
        <div [innerHTML]="fromTheArtistHtml"></div>
        <h6 class="text-green">Educations</h6>
        <p *ngIf="!artist.educations.length">No data</p>
        <ul>
            <li *ngFor="let education of artist.educations">
                {{ education.startDate }}-{{ education.endDate }}
                {{ education.specialization | titlecase }} {{ education.name | titlecase }}
                {{ education.city | titlecase }}, {{ education.country | titlecase }}
            </li>
        </ul>
        <h6 class="text-green">Exhibitions</h6>
        <p *ngIf="!artist.exhibitions.length">No data</p>
        <ul>
            <li *ngFor="let exhibition of artist.exhibitions">
                {{ exhibition.exhibitionDate }}
                {{ exhibition.exhibitionName | titlecase }} {{ eventTypes[exhibition.exhibitionType] | titlecase }}
                {{ exhibition.city | titlecase }}, {{ exhibition.country | titlecase }}
            </li>
        </ul>
    </div>
    <div class="vertical-divider" fxHide.lt-sm="1"></div>
    <div fxFlex.gt-sm="50" fxFlexOrder.lt-sm="1" class="artist-profile-picture">
        <img appPictureLoad [src]="artist.user.pictureMedium | picture" alt="">
    </div>
</div>
