import {Component, Input, OnInit} from '@angular/core';
import {AdminService, AuthService, SharedService} from '../../services';
import {Router} from '@angular/router';
import {MatDrawer} from '@angular/material/sidenav';
import {IAdmin} from '../../interfaces';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    admin: IAdmin;
    @Input() drawer: MatDrawer;
    @Input() sidenavActive: boolean;

    constructor(private authService: AuthService,
                private adminService: AdminService,
                private sharedService: SharedService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.getProfile();
    }

    private getProfile(): void {
        this.adminService
            .getProfile()
            .subscribe((admin) => {
                this.admin = admin;
                if (this.admin.role.canVote) {
                    this.drawer.toggle();
                }
            });
    }

    logout(): void {
        this.authService
            .logout()
            .subscribe(() => {
                this.sharedService.removeToken();
                this.router.navigate(['/auth/login']);
            });
    }
}
