<form id="updatePasswordForm" name="updatePasswordForm" [formGroup]="updatePasswordForm"
      (submit)="updatePassword()"
      novalidate>
    <mat-form-field fxFlexFill>
        <input type="password" matInput placeholder="Current password"
               formControlName="currentPassword" [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix
                  (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="currentPassword.hasError('required')">{{ 'current_password_required' | text }}</mat-error>
    </mat-form-field>
    <mat-form-field fxFlexFill>
        <input type="password" matInput placeholder="New Password" formControlName="password"
               [type]="hide ? 'password' : 'text'">
        <mat-error *ngIf="password.hasError('required')">{{ 'password_required' | text }}</mat-error>
        <mat-error *ngIf="password.hasError('minlength')">{{ 'password_min_length' | text }}</mat-error>
        <mat-error *ngIf="password.hasError('maxlength')">{{ 'password_max_length' | text }}</mat-error>
        <mat-error *ngIf="!password.hasError('minlength') && password.hasError('missing_lowercase')">
            {{ 'missing_lowercase' | text }}
        </mat-error>
        <mat-error *ngIf="!password.hasError('minlength') && password.hasError('missing_uppercase')">
            {{ 'missing_uppercase' | text }}
        </mat-error>
        <mat-error
            *ngIf="!password.hasError('minlength') && password.hasError('missing_numeric')">
            {{ 'missing_numeric' | text }}
        </mat-error>
        <mat-error
            *ngIf="!password.hasError('minlength') && password.hasError('missing_non_alphanumeric')">
            {{ 'missing_non_alphanumeric' | text }}
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlexFill>
        <input type="password" matInput placeholder="Confirm password"
               formControlName="confirmPassword" [type]="hide ? 'password' : 'text'">
        <mat-error *ngIf="confirmPassword.hasError('required')">
            {{ 'confirm_password_required' | text }}
        </mat-error>
        <mat-error *ngIf="confirmPassword.hasError('confirm_password_mismatch')">
            {{ 'confirm_password_mismatch' | text }}
        </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
        <span fxFlex></span>
        <div class="button-underline-right">
            <button class="submit-button button button-1 text-green" type="submit"
                    fxFlexAlign="end end">Update password
            </button>
        </div>
    </div>
</form>
