import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IExhibition} from '../../interfaces';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-exhibition-form',
    templateUrl: './exhibition-form.component.html',
    styleUrls: ['./exhibition-form.component.scss']
})
export class ExhibitionFormComponent implements OnInit, OnChanges {
    @Input() exhibition: IExhibition;
    @Input() exhibitionIndex: number;
    @Input() parentFormSubmitted: boolean;
    @Output() exhibitionRemoved = new EventEmitter<number>();
    exhibitionForm: FormGroup;
    exhibitionName: FormControl;
    exhibitionDate: FormControl;
    exhibitionType: FormControl;
    city: FormControl;
    country: FormControl;
    exhibitionTypes: any = {
        group_exhibition: 'Group Exhibition',
        solo_exhibition: 'Solo Exhibition',
        air_fairs: 'Air Fairs',
        international: 'International'
    };
    dates: number[] = [];
    currentYear: number = moment().year();

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        for (let i = this.currentYear; i >= this.currentYear - 100; i--) {
            this.dates.push(i);
        }

        this.createFormControls();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.parentFormSubmitted && this.exhibitionForm) {
            this.exhibitionName.markAsTouched({onlySelf: true});
            this.exhibitionDate.markAsTouched({onlySelf: true});
            this.exhibitionType.markAsTouched({onlySelf: true});
            this.city.markAsTouched({onlySelf: true});
            this.country.markAsTouched({onlySelf: true});
        }
    }

    removeExhibition(): void {
        this.exhibitionRemoved.emit(this.exhibitionIndex);
    }

    private createFormControls(): void {
        this.exhibitionName = new FormControl(this.exhibition.exhibitionName || '', [
            Validators.required
        ]);
        this.exhibitionType = new FormControl(this.exhibition.exhibitionType || '', [
            Validators.required
        ]);
        this.exhibitionDate = new FormControl(this.exhibition.exhibitionDate || null,
            [
                Validators.required
            ]);
        this.city = new FormControl(this.exhibition.city || '', [Validators.required]);
        this.country = new FormControl(this.exhibition.country || '', [Validators.required]);
        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.exhibitionForm = this.fb.group({
            exhibitionName: this.exhibitionName,
            exhibitionType: this.exhibitionType,
            exhibitionDate: this.exhibitionDate,
            city: this.city,
            country: this.country,
        });

        if (this.parentFormSubmitted) {
            this.exhibitionName.markAsTouched({onlySelf: true});
            this.exhibitionDate.markAsTouched({onlySelf: true});
            this.exhibitionType.markAsTouched({onlySelf: true});
            this.city.markAsTouched({onlySelf: true});
            this.country.markAsTouched({onlySelf: true});
        }

        this.exhibitionForm
            .valueChanges
            .subscribe((result) => {
                Object.keys(result).forEach((key) => {
                    this.exhibition[key] = result[key];
                });
                this.exhibition.valid = this.exhibitionForm.valid;
            });
    }

}
