import { Component, OnInit, Inject } from "@angular/core";
import { IAdmin, IArtwork, IArtist } from "../../interfaces";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ArtworkService, ArtistReviewService } from "../../services";

@Component({
    selector: "app-artwork-info",
    templateUrl: "./artwork-info.component.html",
    styleUrls: ["./artwork-info.component.scss"],
})
export class ArtworkInfoComponent implements OnInit {
    artwork: IArtwork;
    artworkId: string;
    artistId: string;
    admin: IAdmin;
    artist: IArtist;

    constructor(
        private dialogRef: MatDialogRef<ArtworkInfoComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private artworkService: ArtworkService,
    ) {}

    ngOnInit() {
        this.admin = this.data.admin;
        this.artworkId = this.data.artwork.artworkId;
        this.artistId = this.data.artwork.artistId;
        this.getArtwork();
    }

    private getArtwork(): void {
      this.artwork = null;
      this.artworkService
            .getArtworkForAdmin(this.artworkId)
            .subscribe(artwork => this.artwork = artwork);
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
