import {Pipe, PipeTransform} from '@angular/core';
import messages from '../../../assets/messages';

@Pipe({
    name: 'text'
})
export class TextPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return messages[value] || value;
    }
}
