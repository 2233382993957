import {Component, Input, OnInit} from '@angular/core';
import {IAdmin} from '../../interfaces';

@Component({
    selector: 'app-profile-preview',
    templateUrl: './profile-preview.component.html',
    styleUrls: ['./profile-preview.component.scss']
})
export class ProfilePreviewComponent implements OnInit {
    @Input() admin: IAdmin;
    constructor() {
    }

    ngOnInit() {
    }
}
