import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-artwork-zoom',
    templateUrl: './artwork-zoom.component.html',
    styleUrls: ['./artwork-zoom.component.scss']
})
export class ArtworkZoomComponent implements OnInit {
    pictureUrl: any;

    constructor(private dialogRef: MatDialogRef<ArtworkZoomComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {
        this.pictureUrl = this.data.pictureUrl;
    }

    onClose(): void {
        this.dialogRef.close();
    }

}
