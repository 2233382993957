<div>
    <form name="exhibitionForm" [formGroup]="exhibitionForm" novalidate>
        <mat-divider *ngIf="exhibitionIndex"></mat-divider>
        <div fxLayout="row">
            <span fxFlex=""></span>
            <div class="button-underline-right">
                <button class="button button-2 text-green submit-button" type="button"
                        (click)="removeExhibition()"
                        fxFlexAlign="end end">Remove
                </button>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <input matInput formControlName="exhibitionName" placeholder="Exhibition name" required>
                <mat-error *ngIf="exhibitionName.hasError('required')">{{ 'exhibition_name_required' | text }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <mat-label>Exhibition date</mat-label>
                <mat-select [formControl]="exhibitionDate" required>
                    <mat-option *ngFor="let date of dates" [value]="date">
                        {{date}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="exhibitionDate.hasError('required')">{{ 'exhibition_date_required' | text }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="3 3 calc(33.33% - 20px)">
                <mat-label>Exhibition Type</mat-label>
                <mat-select [formControl]="exhibitionType" required>
                    <mat-option value="solo_exhibition">
                        {{exhibitionTypes['solo_exhibition']}}
                    </mat-option>
                    <mat-option value="group_exhibition">
                        {{exhibitionTypes['group_exhibition']}}
                    </mat-option>
                    <mat-option value="international">
                        {{exhibitionTypes['international']}}
                    </mat-option>
                    <mat-option value="air_fairs">
                        {{exhibitionTypes['air_fairs']}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="exhibitionType.hasError('required')">{{ 'exhibition_type_required' | text }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
            <mat-form-field fxFlex>
                <input matInput formControlName="city" placeholder="City" required>
                <mat-error *ngIf="city.hasError('required')">{{ 'city_required' | text }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput formControlName="country" placeholder="Country" required>
                <mat-error *ngIf="country.hasError('required')">{{ 'country_required' | text }}</mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
