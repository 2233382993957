import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IMaterial} from '../interfaces';
import {MaterialService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MaterialsResolverService implements Resolve<IMaterial[]> {

    constructor(private materialService: MaterialService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IMaterial[]> {
        return this.materialService.getMaterials({}).pipe(map(data => data.results));
    }
}
