import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SharedService} from '../services';
import {finalize, map} from 'rxjs/operators';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

    constructor(private sharedService: SharedService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const startDate = moment().valueOf();
        if (request.url.indexOf('/api/pictures/upload') < 0) {
            request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
        }
        request = request.clone({headers: request.headers.set('Authorization', this.sharedService.getToken())});
        request = request.clone({headers: request.headers.set('client', 'admin')});

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            finalize(() => {
                console.log(`${request.url} - ${moment().diff(startDate, 'milliseconds')}ms`);
            })
        );
    }
}
