import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IAdmin} from '../interfaces';
import {AdminService} from '../services';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileResolverService implements Resolve<IAdmin> {

    constructor(private adminService: AdminService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAdmin> {
        return this.adminService.getProfile();
    }
}
