import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IApiReceiveResponse, IApiResponse} from '../interfaces';
import {IArtCenter} from '../interfaces/art-center';

@Injectable({
    providedIn: 'root'
})
export class ArtCenterService {
    constructor(private apiService: ApiService) {
    }

    getArtCenterList(params: any): Observable<IApiReceiveResponse<IArtCenter>> {
        return this.apiService.get<IApiReceiveResponse<IArtCenter>>('art-center/receive/all', {params}, true);
    }

    updateArtCenterItem(artCenterId:string, artCenter: IArtCenter): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`art-center/update/${artCenterId}`, artCenter, {}, true, true);
    }

    addArtCenterItem(artCenter: IArtCenter): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`art-center/add`, artCenter, {}, true, true);
    }

    getArtCenterItem(artCenterId: string): Observable<IArtCenter> {
        return this.apiService.get<IArtCenter>(`art-center/receive/${artCenterId}`, {}, true);
    }

    deleteArtCenterItem(artCenterId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`art-center/delete/${artCenterId}`, {}, true, true);
    }
}
