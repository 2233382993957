import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {IArtist} from '../../interfaces';

@Component({
    selector: 'app-artist-preview',
    templateUrl: './artist-preview.component.html',
    styleUrls: ['./artist-preview.component.scss']
})
export class ArtistPreviewComponent implements OnInit {
    @Input() artist: IArtist;
    eventTypes: any = {
        group_exhibition: 'Group Exhibition',
        solo_exhibition: 'Solo Exhibition',
        air_fairs: 'Air Fairs'
    };
    editorContent: string;
    fromTheArtistContent: string;
    fromTheArtistHtml: SafeHtml;
    safeHtml: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.editorContent = this.artist.about;
        this.fromTheArtistContent = this.artist.fromTheArtist;
        this.safeHtml = this.updateSafeHtml(this.editorContent);
        this.fromTheArtistHtml = this.updateSafeHtml(this.fromTheArtistContent);
    }

    updateSafeHtml(content: string) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

}
