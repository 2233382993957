<div fxLayout="column" fxLayoutGap="30px" *ngIf="artwork">
    <h6>Delete artwork</h6>

    <mat-dialog-content>
        <span class="subtitle subtitle-1">Are you sure you want to delete this artwork?</span>
    </mat-dialog-content>

    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">
        <div class="button-underline-right" align="left">
            <button class="subtitle subtitle-2 text-green submit-button" (click)="close()">Close
            </button>
        </div>
        <div class="button-underline-right" align="left">
            <button class="subtitle subtitle-2 text-green submit-button" (click)="deleteArtwork()">Delete
            </button>
        </div>
    </div>
</div>
