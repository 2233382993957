<div id="artist-preview">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="40px">
        <div fxFlex="50" *ngIf="artwork">
            <div fxLayout="row" class="cover-picture">
                <div fxFlexFill class="artwork-image" (click)="zoom(artwork.pictures[0])">
                    <img appPictureLoad [src]="artwork.pictures[0].medium | picture" alt="">
                </div>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.gt-sm="start center">
                <ng-container *ngFor="let picture of artwork.pictures; let pictureIndex = index">
                    <div fxFlex="25" fxFlex.lt-sm="50" fxFlex.xs="50" style="padding: 0 10px;" *ngIf="pictureIndex">
                        <div class="artwork-gallery" (click)="zoom(picture)">
                            <img appPictureLoad [src]="picture.small | picture" alt="">
                        </div>
                    </div>
                </ng-container>
            </div> 
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="10px">
            <div>
                <h2>{{ artwork.name }}</h2>
            </div>
            <div class="subtitle subtitle-1 text-green" *ngIf="!admin.role.canVote">{{ artwork.artist.user.firstName }} {{ artwork.artist.user.lastName }}</div>
            <div class="caption" *ngIf="!admin.role.canVote">{{ artwork.year }} {{ artwork.artist.user.city }} {{ artwork.artist.user.country }}</div>
            <div class="subtitle subtitle-2">
                <span>W {{ artwork.width | dimensionUnit: admin.dimensionUnit | number:'1.1-1' }} x </span>
                <span>H {{ artwork.height | dimensionUnit: admin.dimensionUnit | number:'1.1-1' }} x </span>
                <span>D {{artwork.depth | dimensionUnit: admin.dimensionUnit | number:'1.1-1'}} </span>
                <span>{{ admin.dimensionUnit }}</span>
            </div>
            <div class="subtitle subtitle-2" *ngIf="!admin.role.canVote">
                <span>WT {{artwork.weight | weightUnit: admin.weightUnit | number:'1.1-1'}} {{ admin.weightUnit }} </span>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" *ngIf="!admin.role.canVote">
                <div fxFlex>
                    <span class="caption price-label">Artist Price</span>
                    <div class="text-green m-0">{{ artwork.basePrice | currency }}</div>
                </div>
                <div fxFlex>
                    <span class="caption price-label">System Price</span>
                    <div class="text-green m-0"> {{ artwork.systemPrice | currency }}</div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" *ngIf="!admin.role.canVote">
                <div fxFlex>
                    <span class="caption price-label">Shipping Price</span>
                    <div class="text-green m-0">{{ artwork.shippingPrice | currency }}</div>
                </div>
                <div fxFlex>
                    <span class="caption price-label">Min Selling Price</span>
                    <div class="text-green m-0"> {{ artwork.price | currency }}</div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" *ngIf="!admin.role.canVote">
                <div fxFlex>
                    <span class="caption price-label">Offering Price</span>
                    <div class="text-green m-0">{{ artwork.completedPrice | currency }}</div>
                </div>
                <div fxFlex>
                    <span class="caption price-label">Allowed Discount</span>
                    <div class="text-green m-0"> {{ artwork.allowedDiscount }} %</div>
                </div>
            </div>
            <div *ngIf="!admin.role.canVote">
                <span class="subtitle subtitle-3 text-green">About</span>
                <div class="m-0">{{ artwork.description }}</div>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutGap="7px" *ngIf="admin.role.canVote">
                <span class="subtitle subtitle-3 text-green">Year:</span>
                <span>{{ artwork.year }}</span>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutGap="7px">
                <span class="subtitle subtitle-3 text-green">Styles:</span>
                <span *ngFor="let style of artwork.styles; let styleIndex = index">
                    <span style="margin-right: 7px;" *ngIf="styleIndex"> | </span>
                    {{ style.name | titlecase }}
                </span>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutGap="7px" *ngIf="!admin.role.canVote">
                <span class="subtitle subtitle-3 text-green">Subjects: </span>
                <span *ngFor="let subject of artwork.subjects; let subjectIndex = index">
                    <span style="margin-right: 7px;" *ngIf="subjectIndex"> | </span>
                    {{ subject.name | titlecase }}
                </span>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutGap="7px">
                <span class="subtitle subtitle-3 text-green">Mediums: </span>
                <span *ngFor="let medium of artwork.mediums; let mediumIndex = index">
                    <span style="margin-right: 7px;" *ngIf="mediumIndex"> | </span>
                    {{ medium.name | titlecase }}
                </span>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutGap="7px">
                <span class="subtitle subtitle-3 text-green">Materials: </span>
                <span *ngFor="let material of artwork.materials; let materialIndex = index">
                    <span style="margin-right: 7px;" *ngIf="materialIndex"> | </span>
                    {{ material.name | titlecase }}
                </span>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" fxLayoutAlign="start center" *ngIf="!admin.role.canVote">
                <span class="subtitle subtitle-3 text-green">Colors: </span>
                <mat-chip-list aria-label="Fish selection" fxLayout.lt-sm="column">
                    <mat-chip *ngFor="let color of artwork.colors"
                              [style.background]="color.code">{{ color.name | titlecase }}</mat-chip>
                </mat-chip-list>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" *ngIf="!admin.role.canVote">
                <span class="subtitle subtitle-3 text-green">Created At:</span>
                <div class="text-italic text-gray">{{ artwork.createdAt | date: 'medium' }}</div>
            </div>
        </div>
    </div>
</div>
<span class="close-button button" (click)="onClose()"><mat-icon>close</mat-icon></span>