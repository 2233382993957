<div fxLayout="row" class="cover-picture">
    <div fxFlexFill class="artwork-image" (click)="zoom(pictures[0])">
        <img appPictureLoad [src]="pictures[0].medium | picture" alt="">
    </div>
</div>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.gt-sm="start center">
    <ng-container *ngFor="let picture of pictures; let pictureIndex = index">
        <div fxFlex="25" fxFlex.lt-sm="50" fxFlex.xs="50" style="padding: 0 10px;" *ngIf="pictureIndex">
            <div class="artwork-gallery" (click)="zoom(picture)">
                <img appPictureLoad [src]="picture.small | picture" alt="">
            </div>
        </div>
    </ng-container>
</div>
