import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ArtworkReviewService, SharedService} from '../../services';

@Component({
    selector: 'app-update-artwork-review',
    templateUrl: './update-artwork-review.component.html',
    styleUrls: ['./update-artwork-review.component.scss']
})
export class UpdateArtworkReviewComponent implements OnInit {
    updateArtworkReviewForm: FormGroup;
    message: FormControl;
    private artworkId: string;

    constructor(private dialogRef: MatDialogRef<UpdateArtworkReviewComponent>,
                private fb: FormBuilder,
                private sharedService: SharedService,
                private artworkReviewService: ArtworkReviewService,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {
        this.createFormControls();
        this.artworkId = this.data.artworkId;
    }

    private createFormControls(): void {
        this.message = new FormControl('', [Validators.required]);
        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.updateArtworkReviewForm = this.fb.group({
            message: this.message
        });
    }

    adminReview(): void {
        if (!this.updateArtworkReviewForm.valid) {
            return;
        }

        const message = this.message.value;

        if (!this.artworkId || !message) {
            return;
        }

        this.artworkReviewService
            .adminReview(this.artworkId, 'update', message)
            .subscribe(() => {
                this.sharedService.emitArtworkReviewChange();
                this.close();
            });
    }

    close() {
        this.dialogRef.close();
    }
}
