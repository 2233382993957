import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IShippingZonePrice} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class ShippingZonePriceService {

    constructor(private apiService: ApiService) {
    }

    getShippingZonePrices(params: any): Observable<IApiReceiveResponse<IShippingZonePrice>> {
        return this.apiService.get<IApiReceiveResponse<IShippingZonePrice>>('shipping-zone-prices/receive/all', {params}, true);
    }

    getShippingZonePrice(shippingZonePriceId: string): Observable<IShippingZonePrice> {
        return this.apiService.get<IShippingZonePrice>(`shipping-zone-prices/receive/${shippingZonePriceId}`, {}, true);
    }

    addShippingZonePrice(shippingZonePrice: IShippingZonePrice): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`shipping-zone-prices/add`, shippingZonePrice, {}, true, true);
    }

    updateShippingZonePrice(shippingZonePriceId: string, shippingZonePrice: IShippingZonePrice): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`shipping-zone-prices/update/${shippingZonePriceId}`, shippingZonePrice, {}, true, true);
    }

    deleteShippingZonePrice(shippingZonePriceId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`shipping-zone-prices/delete/${shippingZonePriceId}`, {}, true, true);
    }
}
