import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, ISubject} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SubjectService {

    constructor(private apiService: ApiService) {
    }

    getSubjects(params: any): Observable<IApiReceiveResponse<ISubject>> {
        return this.apiService.get<IApiReceiveResponse<ISubject>>('subjects/receive/all', {params}, true).pipe(
            map(({results, count}) => {
                if (params.categoryId) {
                    results = results.map((subject) => {
                        if (subject.categorySubjects && subject.categorySubjects.length) {
                            subject.categorySubjectId = subject.categorySubjects[0].categorySubjectId;
                        }
                        delete subject.categorySubjects;
                        return subject;
                    });
                }

                return {results, count};
            })
        );
    }

    getSubject(subjectId: string): Observable<ISubject> {
        return this.apiService.get<ISubject>(`subjects/receive/${subjectId}`, {}, true);
    }

    addSubject(subject: ISubject): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`subjects/add`, subject, {}, true, true);
    }

    updateSubject(subjectId: string, subject: ISubject): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`subjects/update/${subjectId}`, subject, {}, true, true);
    }

    deleteSubject(subjectId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`subjects/delete/${subjectId}`, {}, true, true);
    }

    getCategorySubjects(params: any): Observable<IApiReceiveResponse<ISubject>> {
        return this.apiService.get<IApiReceiveResponse<ISubject>>('subjects/category-subject/receive/all', {params}, true)
            .pipe(map(({results, count}) => {
                results = results.map((subject) => {
                    subject.name = subject.subject.name;
                    delete subject.subject;
                    return subject;
                });
                return {results, count};
            }));
    }

    getCategorySubject(categorySubjectId: string): Observable<ISubject> {
        return this.apiService.get<ISubject>(`subjects/category-subject/receive/${categorySubjectId}`, {}, true);
    }

    addCategorySubject(categoryId: string, subjectId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`subjects/category-subject/add/${categoryId}/${subjectId}`, {}, {}, true, true);
    }

    updateCategorySubject(categorySubjectId: string, order: number): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`subjects/category-subject/update/${categorySubjectId}`, {order}, {}, true, true);
    }

    deleteCategorySubject(categorySubjectId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`subjects/category-subject/delete/${categorySubjectId}`, {}, true, true);
    }
}
