import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IArtwork} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import {SharedService} from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class OfferService {

    constructor(private apiService: ApiService,
                private sharedService: SharedService) {
    }

    getArtworks(params: any): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('offers/artwork/receive/all', {params}, true);
    }

    getOffers(artworkId: string): Observable<IArtwork> {
        return this.apiService.get<IArtwork>(`offers/offer/receive/${artworkId}`, {}, true)
            .pipe(map(artwork => this.sharedService.formatArtwork(artwork)));
    }

    approveOffer(offerId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`offers/approve/${offerId}`, {}, {}, true);
    }

    declineOffer(offerId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`offers/decline/${offerId}`, {}, {}, true);
    }
}
