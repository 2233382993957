import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IColor} from '../interfaces';
import {ColorService} from '../services';

@Injectable({
    providedIn: 'root'
})
export class ColorsResolverService implements Resolve<IColor[]> {

    constructor(private colorService: ColorService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IColor[]> {
        return new Promise<IColor[]>((resolve, reject) => {
            this.colorService
                .getColors({})
                .subscribe((data) => {
                    resolve(data.results);
                }, (err) => {
                    reject(err);
                });
        });
    }
}
