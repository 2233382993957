import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IArtist, IArtwork} from '../interfaces';
import ntc from '../../../assets/ntc';

const APP_TOKEN = 'APP_ADMIN_TOKEN';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private adminChanged = new Subject();
    private artworkReviewChanged = new Subject();
    private loaderChanged = new Subject();

    constructor() {
    }

    getOrientations() {
        return [
            {
                key: 'portrait',
                name: 'Portrait'
            },
            {
                key: 'landscape',
                name: 'Landscape'
            },
            {
                key: 'square',
                name: 'Square'
            },
            {
                key: 'circle',
                name: 'Circle'
            },
            {
                key: 'other',
                name: 'Other'
            }
        ];
    }

    getSizes() {
        return [
            {
                key: 'small',
                name: 'Small'
            },
            {
                key: 'medium',
                name: 'Medium'
            },
            {
                key: 'large',
                name: 'Large'
            },
            {
                key: 'oversize',
                name: 'Oversize'
            }
        ];
    }

    getToken(): string {
        return localStorage.getItem(APP_TOKEN) || '';
    }

    setToken(token: string): void {
        localStorage.setItem(APP_TOKEN, token);
    }

    removeToken(): void {
        localStorage.removeItem(APP_TOKEN);
    }

    emitAdminChange() {
        this.adminChanged.next();
    }

    onAdminChange(): Observable<any> {
        return this.adminChanged.asObservable();
    }

    emitArtworkReviewChange() {
        this.artworkReviewChanged.next();
    }

    onArtworkReviewChange(): Observable<any> {
        return this.artworkReviewChanged.asObservable();
    }

    emitLoaderChange(loading: boolean) {
        this.loaderChanged.next(loading);
    }

    onLoaderChange(): Observable<any> {
        return this.loaderChanged.asObservable();
    }

    formatArtist(artist: any): IArtist {
        artist.specializations = artist.artistSpecializations.map(item => item.specialization);
        delete artist.artistSpecializations;
        return artist;
    }

    formatArtwork(artwork: any): IArtwork {
        artwork.styles = artwork.artworkStyles.map(artworkStyle => artworkStyle.style);
        artwork.subjects = artwork.artworkSubjects.map(artworkSubject => artworkSubject.subject);
        artwork.mediums = artwork.artworkMediums.map(artworkMedium => artworkMedium.medium);
        artwork.materials = artwork.artworkMaterials.map(artworkMaterial => artworkMaterial.material);
        artwork.colors = artwork.artworkColors.map(artworkColor => {
            artworkColor.color.name = ntc.name(artworkColor.color.code)[1];
            return artworkColor.color;
        });
        delete artwork.artworkStyles;
        delete artwork.artworkSubjects;
        delete artwork.artworkMediums;
        delete artwork.artworkMaterials;
        delete artwork.artworkColors;
        return artwork;
    }
}
