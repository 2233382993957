import {Injectable} from '@angular/core';
import {SharedService} from './shared.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IApiReceiveResponse, IApiResponse, IArtwork} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class ArtworkReviewService {

    constructor(private apiService: ApiService,
                private sharedService: SharedService) {
    }

    getArtworks(params: any): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artwork-review/admin/receive/all', {params}, true);
    }

    getArtwork(artworkId: string): Observable<IArtwork> {
        return this.apiService.get<any>(`artwork-review/admin/receive/${artworkId}`, {}, true)
            .pipe(map((artwork) => this.sharedService.formatArtwork(artwork)));
    }

    adminReview(artworkId: string, type: string, message?: string): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artwork-review/${type}/admin/${artworkId}`, {message}, {}, true, true);
    }
}
