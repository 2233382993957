<div fxLayout="column" fxLayoutGap="40px">
    <div class="main-drop-box">
        <div class="file-upload-message">
            <svg xmlns="http://www.w3.org/2000/svg" width="100.005" height="82.591" viewBox="0 0 100.005 82.591">
                <g id="Icon:_Image" data-name="Icon: Image" transform="translate(0 0)">
                    <path id="Path_9566" data-name="Path 9566" d="M325.578,180.966a1.973,1.973,0,0,1,0-3.946A19.341,19.341,0,0,0,344.9,157.7V125.777l-.494-.221a107.756,107.756,0,0,0-25.334-7.46,1.942,1.942,0,0,1-1.569-1.487l-1.044-4.485a10.558,10.558,0,0,0-10.307-8.185h-14.62a10.554,10.554,0,0,0-10.307,8.185l-1.044,4.48A1.979,1.979,0,0,1,278.6,118.1a106.353,106.353,0,0,0-25.329,7.46l-.494.226V157.7a19.34,19.34,0,0,0,19.315,19.315A1.977,1.977,0,0,1,274.071,179a1.952,1.952,0,0,1-1.976,1.971A23.284,23.284,0,0,1,248.834,157.7V124.553a1.977,1.977,0,0,1,1.051-1.734c.107-.061,9.9-5.231,26.2-8.282l.54-.1.75-3.2A14.5,14.5,0,0,1,291.529,100h14.62a14.461,14.461,0,0,1,14.145,11.229l.757,3.2.54.1c16.353,3.087,26.091,8.221,26.178,8.272a1.925,1.925,0,0,1,1.07,1.744V157.7A23.284,23.284,0,0,1,325.578,180.966Z" transform="translate(-248.834 -100)" fill="#dfdfdf"/>
                    <path id="Path_9567" data-name="Path 9567" d="M254.688,111.953a2.022,2.022,0,0,1-1.893-1.373,1.971,1.971,0,0,1,1.255-2.465c3.066-1,6.312-1.918,9.655-2.716h.165a1.99,1.99,0,0,1,2.19,1.455,1.953,1.953,0,0,1-1.45,2.367c-3.224.766-6.353,1.65-9.315,2.628A1.685,1.685,0,0,1,254.688,111.953Z" transform="translate(-246.314 -96.48)" fill="#dfdfdf"/>
                    <path id="Path_9568" data-name="Path 9568" d="M291.566,174.914a31.568,31.568,0,1,1,31.558-31.558A31.6,31.6,0,0,1,291.566,174.914Zm0-59.2a27.628,27.628,0,1,0,27.612,27.619A27.66,27.66,0,0,0,291.566,115.718Z" transform="translate(-241.551 -92.323)" fill="#dfdfdf"/>
                </g>
            </svg>
            <p>Drag and drop a file here or click</p>
        </div>
        <div class="mask rgba-stylish-slight"></div>
        <input type="file" class="file_upload" [formControl]="inputControl"
               (change)="onSelectFiles($event)" accept="image/*" multiple>
        <div class="file-upload-preview" [ngStyle]="{'display': pictures.length ? 'block' : 'none'}"><span
            class="file-upload-render"></span>
            <div class="file-upload-infos">
                <div class="file-upload-infos-inner">
                </div>
            </div>
        </div>
    </div>
</div>
<div fxLayout="column" fxLayout.xs="column" fxLayoutGap="10px">
    <span class="body-2 text-italic text-gray" [ngClass]="{ 'text-red': invalidPicture }">Please upload high resolution photos: minimum resolution {{ company.minPictureResolution }} pixels, maximum size {{ company.maxArtworkSize }} MB, format JPEG or PNG</span>
    <span class="body-2 text-italic text-gray" *ngIf="company.artworkPictureFrom === 1">You may upload up to {{ company.artworkPictureTo }} images of the same artwork</span>
    <span class="body-2 text-italic text-gray" *ngIf="company.artworkPictureFrom !== 1">You may upload from {{ company.artworkPictureFrom }} to {{ company.artworkPictureTo }} images of the same artwork</span>
</div>
<div fxLayout="row wrap" *ngIf="pictures.length > 0" fxLayout.xs="column" cdkDropList
     cdkDropListOrientation="horizontal" class="gallery-list"
     (cdkDropListDropped)="drop($event)">
    <div fxFlex="30" class="gallery-box" *ngFor="let picture of pictures; let i=index"
         [style.display]="picture.deleted ? 'none' : 'block'" cdkDrag>
        <div class="picture"
             [style.background-image]="'url('+ (picture.pictureId ? (picture.small | picture) : picture.url) +')'"></div>
        <div class="mask rgba-stylish-slight"></div>
        <button mat-icon-button color="warn" type="button" (click)="onDelete(i)" class="delete-button">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
<div fxLayout="column" fxLayoutGap="10px">
    <mat-error class="body-2 text-italic text-red" *ngIf="!filteredPictures.length && parentFormSubmitted">
        {{ 'artwork_pictures_required' | text }}
    </mat-error>
    <mat-error class="body-2 text-italic text-red" *ngIf="filteredPictures.length && filteredPictures.length < company.artworkPictureFrom && company.artworkPictureFrom > 1">
        {{ 'artwork_pictures_min' | text: company.artworkPictureFrom }}
    </mat-error>
    <mat-error class="body-2 text-italic text-red" *ngIf="filteredPictures.length && filteredPictures.length > company.artworkPictureTo">
        {{ 'artwork_pictures_max' | text: company.artworkPictureTo }}
    </mat-error>
</div>


