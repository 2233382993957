import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ISubject} from '../interfaces';
import {SubjectService} from '../services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SubjectsResolverService implements Resolve<ISubject[]> {

    constructor(private subjectService: SubjectService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ISubject[]> {
        return this.subjectService.getSubjects({}).pipe(map(data => data.results));
    }
}
