import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ChartsModule} from 'ng2-charts';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {OrderModule} from 'ngx-order-pipe';
import {ImageCropperModule} from 'ngx-image-cropper';
// components
import {DimensionUnitPipe, WeightUnitPipe, TextPipe, PicturePipe} from './pipes';
import {RequestInterceptorService} from './interceptors';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {SideBarComponent} from './components/side-bar/side-bar.component';
import {HeaderComponent} from './components/header/header.component';
import {BaseComponent} from './components/base/base.component';
import {LoaderComponent} from './components/loader/loader.component';
import {ArtistPreviewComponent} from './components/artist-preview/artist-preview.component';
import {ArtworkPictureUploaderComponent} from './components/artwork-picture-uploader/artwork-picture-uploader.component';
import {ArtworkPreviewComponent} from './components/artwork-preview/artwork-preview.component';
import {ChipAutocompleteComponent} from './components/chip-autocomplete/chip-autocomplete.component';
import {EducationFormComponent} from './components/education-form/education-form.component';
import {ExhibitionFormComponent} from './components/exhibition-form/exhibition-form.component';
import {GalleryComponent} from './components/gallery/gallery.component';
import {NoDataComponent} from './components/no-data/no-data.component';
import {ArtworkInfoComponent} from './dialogs/artwork-info/artwork-info.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {ProfilePreviewComponent} from './components/profile-preview/profile-preview.component';
import {TopnavComponent} from './components/topnav/topnav.component';
import {UpdatePasswordComponent} from './components/update-password/update-password.component';
import {UpdateProfileComponent} from './components/update-profile/update-profile.component';
import {ArtworkZoomComponent} from './dialogs/artwork-zoom/artwork-zoom.component';
import {DeleteArtworkComponent} from './dialogs/delete-artwork/delete-artwork.component';
import {UpdateArtworkReviewComponent} from './dialogs/update-artwork-review/update-artwork-review.component';
import { PictureLoadDirective } from './directives/picture-load.directive';
import {AddressBookPreviewComponent} from './components/address-book-preview/address-book-preview.component';
import {PaymentMethodPreviewComponent} from './components/payment-method-preview/payment-method-preview.component';
import {ArtworksListComponent} from './components/artworks-list/artworks-list.component';
import { TitlePipe } from './pipes/title.pipe';
import { ProfilePictureUploaderComponent } from './components/profile-picture-uploader/profile-picture-uploader.component';
import {ArtCenterPictureUploaderComponent} from './components/art-center-picture-uploader/art-center-picture-uploader.component';

@NgModule({
    declarations: [
        // components
        LoaderComponent,
        SideBarComponent,
        HeaderComponent,
        BaseComponent,
        ArtistPreviewComponent,
        ArtworkPictureUploaderComponent,
        ArtworkPreviewComponent,
        ChipAutocompleteComponent,
        EducationFormComponent,
        ExhibitionFormComponent,
        GalleryComponent,
        NoDataComponent,
        ArtworkInfoComponent,
        PaginationComponent,
        ProfilePreviewComponent,
        TopnavComponent,
        UpdatePasswordComponent,
        UpdateProfileComponent,
        AddressBookPreviewComponent,
        PaymentMethodPreviewComponent,
        ArtworksListComponent,
        // pipes
        TextPipe,
        PicturePipe,
        DimensionUnitPipe,
        WeightUnitPipe,
        // dialogs
        ArtworkZoomComponent,
        DeleteArtworkComponent,
        UpdateArtworkReviewComponent,
        // directives
        PictureLoadDirective,
        TitlePipe,
        ProfilePictureUploaderComponent,
        ProfilePictureUploaderComponent,
        ArtCenterPictureUploaderComponent
    ],
    providers: [
        // interceptors
        {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true}
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatListModule,
        MatMenuModule,
        MatTableModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatBottomSheetModule,
        MatCardModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatChipsModule,
        MatSnackBarModule,
        MatSidenavModule,
        DragDropModule,
        MatTabsModule,
        FilterPipeModule,
        MatPaginatorModule,
        ChartsModule,
        NgxImageZoomModule,
        MatDatepickerModule,
        MatNativeDateModule,
        OrderModule,
        ImageCropperModule
    ],
    exports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatListModule,
        MatMenuModule,
        MatTableModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatBottomSheetModule,
        MatCardModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatChipsModule,
        MatSnackBarModule,
        MatSidenavModule,
        DragDropModule,
        MatTabsModule,
        FilterPipeModule,
        MatPaginatorModule,
        LoaderComponent,
        ChartsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        OrderModule,
        // components
        LoaderComponent,
        SideBarComponent,
        HeaderComponent,
        BaseComponent,
        ArtistPreviewComponent,
        ArtworkPictureUploaderComponent,
        ArtworkPreviewComponent,
        ChipAutocompleteComponent,
        EducationFormComponent,
        ExhibitionFormComponent,
        GalleryComponent,
        NoDataComponent,
        ArtworkInfoComponent,
        PaginationComponent,
        ProfilePreviewComponent,
        TopnavComponent,
        UpdatePasswordComponent,
        UpdateProfileComponent,
        AddressBookPreviewComponent,
        PaymentMethodPreviewComponent,
        ArtworksListComponent,
        // pipes
        TextPipe,
        PicturePipe,
        DimensionUnitPipe,
        WeightUnitPipe,
        TitlePipe,
        // dialogs
        ArtworkZoomComponent,
        DeleteArtworkComponent,
        UpdateArtworkReviewComponent,
        // directives
        PictureLoadDirective,
        ProfilePictureUploaderComponent,
        ArtCenterPictureUploaderComponent
    ]
})
export class SharedModule {
}
