<div fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="column" fxLayoutGap="8px">
        <span class="subtitle subtitle-3" *ngIf="paymentMethod.defaultPaymentMethod && showDefault">Default
        </span>
        <span class="subtitle subtitle-3">
            {{ paymentMethod.cardBrand | titlecase }} ending in {{ paymentMethod.cardLast4 }}
        </span>
        <p class="text-light">
            Expires <span *ngIf="paymentMethod.cardExpireMonth < 10">0</span>{{ paymentMethod.cardExpireMonth }}/{{ paymentMethod.cardExpireYear }}
        </p>
    </div>
    <div fxLayout="column" fxLayoutGap="8px" *ngIf="showBilling">
        <span class="subtitle subtitle-3">Billing Address</span>
        <app-address-book-preview [addressBookItem]="paymentMethod.addressBookItem" [showDefault]="showDefault"></app-address-book-preview>
    </div>
</div>
