<div fxLayout="row" fxLayout.xs="column">
    <div fxFlex fxLayout="column">
        <div fxLayout="column" *ngFor="let menuItem of menuList" [fxHide]="!menuItem.access">
            <a [routerLink]="menuItem.url"
               class="menu-link"
               [class.active]="router.isActive(menuItem.url, false)"
               [queryParams]="menuItem.params">
                <span class="subtitle subtitle-3 text-strong">{{menuItem.name}}</span>
            </a>
            <mat-divider></mat-divider>
        </div>
    </div>
</div>
