import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'weightUnit'
})
export class WeightUnitPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (args === 'kg') {
            return value;
        }

        if (args === 'pound') {
            return value * 2.20462;
        }
        return null;
    }

}
