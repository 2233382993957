<app-header [drawer]="drawer" [sidenavActive]="sidenavActive"></app-header>
<mat-drawer-container class="base-container">
    <mat-drawer #drawer mode="side" [(opened)]="sidenavActive">
        <app-side-bar [drawer]="drawer" [tabletMediaMatcher]="tabletMediaMatcher" [admin]="admin"></app-side-bar>
    </mat-drawer>
    <mat-drawer-content [ngClass]="{'mobile': tabletMediaMatcher.matches}">
        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <div class="overlay" *ngIf="sidenavActive" (click)="drawer.close()">
        </div>
    </mat-drawer-content>
</mat-drawer-container>
