import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IShippingZone} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class ShippingZoneService {

    constructor(private apiService: ApiService) {
    }

    getShippingZones(params: any): Observable<IApiReceiveResponse<IShippingZone>> {
        return this.apiService.get<IApiReceiveResponse<IShippingZone>>('shipping-zones/receive/all', {params}, true);
    }

    getShippingZone(shippingZoneId: string): Observable<IShippingZone> {
        return this.apiService.get<IShippingZone>(`shipping-zones/receive/${shippingZoneId}`, {}, true);
    }

    addShippingZone(shippingZone: IShippingZone): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`shipping-zones/add`, shippingZone, {}, true, true);
    }

    updateShippingZone(shippingZoneId: string, shippingZone: IShippingZone): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`shipping-zones/update/${shippingZoneId}`, shippingZone, {}, true, true);
    }

    deleteShippingZone(shippingZoneId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`shipping-zones/delete/${shippingZoneId}`, {}, true, true);
    }
}
