<div class="header" *ngIf="admin">
    <mat-toolbar class="mat-elevation-z6" color="primary">
        <button mat-icon-button (click)="!admin.role.canVote && drawer.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        Armenian Artists Project
        <div fxFlex></div>
        <button mat-icon-button [matMenuTriggerFor]="userMenu">
            <mat-icon>perm_identity</mat-icon>
        </button>
    </mat-toolbar>
</div>

<mat-menu #userMenu="matMenu">
    <button mat-menu-item [routerLink]="['/home/profile']">Profile</button>
    <button mat-menu-item (click)="logout()">Log out</button>
</mat-menu>
