<h3 mat-dialog-title>Update Artwork Review</h3>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="updateArtworkReviewForm" id="updateArtworkReviewForm" name="updateArtworkReviewForm" (ngSubmit)="adminReview()" novalidate>
        <mat-form-field fxFlexFill>
            <textarea matInput placeholder="Reason" formControlName="message" rows="10"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button color="primary" type="submit" form="updateArtworkReviewForm" [disabled]="!updateArtworkReviewForm.valid">Send
    </button>
</mat-card-actions>

