import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {AdminService, SharedService} from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanLoad {

    constructor(private sharedService: SharedService,
                private router: Router,
                private adminService: AdminService) {
    }

    canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const token: string = this.sharedService.getToken();

            if (!token) {
                setTimeout(() => {
                    this.router.navigate(['/home']);
                }, 0);
                return resolve(false);
            }

            this.adminService
                .getProfile()
                .subscribe(() => {
                    return resolve(true);
                }, (err) => {
                    if (err.status === 401) {
                        this.sharedService.removeToken();
                        setTimeout(() => {
                            this.router.navigate(['/auth/login']);
                        }, 0);
                    }
                    return resolve(false);
                });
        });
    }
}
