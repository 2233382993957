<mat-form-field fxFlexFill>
    <mat-chip-list #valueChipList>
        <mat-chip
            *ngFor="let value of selectedValues; let valueIndex = index;"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(valueIndex)">
            <mat-icon *ngIf="mode === 'color'" [ngStyle]="{ 'color': value.code }">fiber_manual_record</mat-icon>
            {{value.name | titlecase}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input [placeholder]="heading"
               #valueInput
               [formControl]="valueControl"
               [matAutocomplete]="valueAuto"
               [matChipInputFor]="valueChipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur">
    </mat-chip-list>
    <mat-autocomplete #valueAuto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredValues | orderBy: 'order' | filterBy: { name: valueControl.value }"
                    [ngStyle]="{ 'background-color': mode === 'color' ? item.code : '' }"
                    [value]="item">
            {{item.name | titlecase}}
        </mat-option>
    </mat-autocomplete>
    <img matSuffix src="../../../../assets/icons/arrow-icon.svg">
</mat-form-field>
<mat-error class="chip-autocomplete-error-message" *ngIf="valueControl.touched && !selectedValues.length && validate">{{ (prefix + mode + '_required') | text }}</mat-error>
<mat-error class="chip-autocomplete-error-message" *ngIf="valueControl.touched && selectedValues.length > max && validate">{{ (prefix + mode + '_max') | text: max }}</mat-error>
