import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, ICountry} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    constructor(private apiService: ApiService) {
    }

    getCountries(params: any): Observable<IApiReceiveResponse<ICountry>> {
        return this.apiService.get<IApiReceiveResponse<ICountry>>('countries/receive/all', {params}, true);
    }

    getCountry(countryId: string): Observable<ICountry> {
        return this.apiService.get<ICountry>(`countries/receive/${countryId}`, {}, true);
    }

    addCountry(country: ICountry): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`countries/add`, country, {}, true, true);
    }

    updateCountry(countryId: string, country: ICountry): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`countries/update/${countryId}`, country, {}, true, true);
    }

    deleteCountry(countryId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`countries/delete/${countryId}`, {}, true, true);
    }
}
