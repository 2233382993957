import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, ICustomer} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private apiService: ApiService) {
    }

    getCustomers(params: any): Observable<IApiReceiveResponse<ICustomer>> {
        return this.apiService.get<IApiReceiveResponse<ICustomer>>('customers/receive/all', {params}, true);
    }
}
