import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IAdmin, IArtwork, IUser} from '../../interfaces';

@Component({
    selector: 'app-artworks-list',
    templateUrl: './artworks-list.component.html',
    styleUrls: ['./artworks-list.component.scss']
})
export class ArtworksListComponent implements OnInit {
    @Input() artwork: IArtwork;
    @Input() admin: IAdmin;
    @Input() imageWidth: string;
    @Input() imageHeight: string;
    @Input() editButton: boolean;
    @Input() removeButton: boolean;
    @Input() marginTop: string;
    @Input() marginBottom: string;
    @Input() bigStyle: boolean;
    @Input() smallStyle: boolean;
    @Input() gap: string;
    @Input() shippingDetail: boolean;
    @Input() offerStyle: boolean;
    @Output() removed = new EventEmitter<IArtwork>();

    constructor() {
    }

    ngOnInit() {
    }

    removeCartItem(artwork: IArtwork) {
        this.removed.emit(artwork);
    }
}
