<div fxLayout="row">
    <div fxLayout="column" fxLayoutGap="30px">
        <div fxLayout="column" fxLayoutGap="8px">
                <span class="subtitle subtitle-3" *ngIf="(addressBookItem.defaultAddress
                || addressBookItem.defaultAddress) && showDefault">
                Default
            </span>
            <span class="subtitle subtitle-1">
                {{ addressBookItem.firstName }} {{ addressBookItem.lastName }}
            </span>
            <div fxLayout="column" fxLayoutAlign="start start" class="text-light">
                <span>
                    {{ addressBookItem.address }},
                    <span *ngIf="addressBookItem.secondAddress">{{ addressBookItem.secondAddress }},</span>
                    {{ addressBookItem.city }}
                </span>
                <span class="body-1">
                    {{ addressBookItem.state }}, {{ addressBookItem.postalCode }}, {{ addressBookItem.country }}
                </span>
                <span class="body-1">+{{ addressBookItem.phone }}</span>
                <span class="body-1" *ngIf="addressBookItem.secondPhone">
                    +{{ addressBookItem.secondPhone }}
                </span>
            </div>
        </div>
    </div>
</div>
