import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IArtist} from '../interfaces';
import {ArtistService} from '../services';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ArtistResolverService implements Resolve<IArtist> {

    constructor(private artistService: ArtistService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IArtist> {
        const artistId = route.params.artistId;
        return this.artistService.getArtist(artistId);
    }
}
