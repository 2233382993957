import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PaginationGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const params = {...route.queryParams};
        const url = state.url.split('?')[0];
        if (!params.offset || !params.limit) {
            params.offset = 0;
            params.limit = 20;
            this.router.navigate([url], {queryParams: params});
            return false;
        }

        const limit = +params.limit;
        if (limit < 20 || limit > 100) {
            params.limit = 20;
            this.router.navigate([url], {queryParams: params});
            return false;
        }
        return true;
    }
}
