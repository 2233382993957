import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IArtwork} from '../../interfaces';
import {ArtworkService} from '../../services';

@Component({
    selector: 'app-delete-artwork',
    templateUrl: './delete-artwork.component.html',
    styleUrls: ['./delete-artwork.component.scss']
})
export class DeleteArtworkComponent implements OnInit {
    artwork: IArtwork;

    constructor(private dialogRef: MatDialogRef<DeleteArtworkComponent>,
                private artworkService: ArtworkService,
                @Inject(MAT_DIALOG_DATA) private data: { artworkId: string }) {
    }

    ngOnInit() {
        this.getArtwork();
    }

    private getArtwork(): void {
        this.artworkService
            .getArtworkForAdmin(this.data.artworkId)
            .subscribe(artwork => this.artwork = artwork);
    }

    deleteArtwork() {
        if (this.artwork.state === 'active' || this.artwork.orderId || this.artwork?.customerId) {
            return;
        }

        this.artworkService
            .deleteArtwork(this.artwork.artworkId)
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }

    close() {
        this.dialogRef.close();
    }
}
