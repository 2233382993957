import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IMaterial} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MaterialService {

    constructor(private apiService: ApiService) {
    }

    getMaterials(params: any): Observable<IApiReceiveResponse<IMaterial>> {
        return this.apiService.get<IApiReceiveResponse<IMaterial>>('materials/receive/all', {params}, true).pipe(
            map(({results, count}) => {
                if (params.categoryId) {
                    results = results.map((material) => {
                        if (material.categoryMaterials && material.categoryMaterials.length) {
                            material.categoryMaterialId = material.categoryMaterials[0].categoryMaterialId;
                        }
                        delete material.categoryMaterials;
                        return material;
                    });
                }

                return {results, count};
            })
        );
    }

    getMaterial(materialId: string): Observable<IMaterial> {
        return this.apiService.get<IMaterial>(`materials/receive/${materialId}`, {}, true);
    }

    addMaterial(material: IMaterial): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`materials/add`, material, {}, true, true);
    }

    updateMaterial(materialId: string, material: IMaterial): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`materials/update/${materialId}`, material, {}, true, true);
    }

    deleteMaterial(materialId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`materials/delete/${materialId}`, {}, true, true);
    }

    getCategoryMaterials(params: any): Observable<IApiReceiveResponse<IMaterial>> {
        return this.apiService.get<IApiReceiveResponse<IMaterial>>('materials/category-material/receive/all', {params}, true)
            .pipe(map(({results, count}) => {
                results = results.map((material) => {
                    material.name = material.material.name;
                    delete material.material;
                    return material;
                });
                return {results, count};
            }));
    }

    getCategoryMaterial(categoryMaterialId: string): Observable<IMaterial> {
        return this.apiService.get<IMaterial>(`materials/category-material/receive/${categoryMaterialId}`, {}, true);
    }

    addCategoryMaterial(categoryId: string, materialId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`materials/category-material/add/${categoryId}/${materialId}`, {}, {}, true, true);
    }

    updateCategoryMaterial(categoryMaterialId: string, order: number): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`materials/category-material/update/${categoryMaterialId}`, {order}, {}, true, true);
    }

    deleteCategoryMaterial(categoryMaterialId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`materials/category-material/delete/${categoryMaterialId}`, {}, true, true);
    }
}
