import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {IPicture} from '../../interfaces';
import {ArtworkZoomComponent} from '../../dialogs/artwork-zoom/artwork-zoom.component';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    @Input() pictures: IPicture[];

    constructor(private dialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    zoom(pictureName: any): void {
        this.dialog.open(ArtworkZoomComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            data: {pictureUrl: pictureName},
            panelClass: 'artwork-zoom-modal'
        });
    }
}
