import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IColor} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import ntc from '../../../assets/ntc';

@Injectable({
    providedIn: 'root'
})
export class ColorService {

    constructor(private apiService: ApiService) {
    }

    getColors(params: any): Observable<IApiReceiveResponse<IColor>> {
        return this.apiService.get<IApiReceiveResponse<IColor>>('colors/receive/all', {params}, true).pipe(
            map(({results, count}) => {
                results = results.map((color) => {
                    color.name = ntc.name(color.code)[1];
                    return color;
                });
                return {results, count};
            })
        );
    }

    getColor(colorId: string): Observable<IColor> {
        return this.apiService.get<IColor>(`colors/receive/${colorId}`, {}, true);
    }

    addColor(color: IColor): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`colors/add`, color, {}, true, true);
    }

    updateColor(colorId: string, color: IColor): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`colors/update/${colorId}`, color, {}, true, true);
    }

    deleteColor(colorId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`colors/delete/${colorId}`, {}, true, true);
    }
}
