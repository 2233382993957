import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IApiResponse} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private apiService: ApiService) {
    }

    login(data: any): Observable<{ token: string }> {
        return this.apiService.post<{ token: string }>(`auth/admin/login`, data, {}, true);
    }

    register(data: any): Observable<{ token: string }> {
        return this.apiService.post<{ token: string }>(`auth/admin/register`, data, {}, true);
    }

    forgotPassword(email: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`auth/admin/forgot-password`, {email}, {}, true, true);
    }

    resetPassword(email: string, password: string, resetPasswordCode: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`auth/admin/reset-password`, {
            email,
            password,
            resetPasswordCode
        }, {}, true, true);
    }

    logout(): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`auth/admin/logout`, {}, {}, true);
    }

    createCustomerByAdmin(data: any): Observable<any> {
        return this.apiService.post<any>(`auth/admin/createCustomerByAdmin`, data, {}, true);
    }
}
