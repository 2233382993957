import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IArtist, IUser} from '../interfaces';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {SharedService} from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class ArtistService {

    constructor(private apiService: ApiService,
                private sharedService: SharedService) {
    }

    getArtists(params?: any): Observable<IApiReceiveResponse<IArtist>> {
        return this.apiService.get<IApiReceiveResponse<IArtist>>('artists/receive/all', {params}, true);
    }

    getArtistsForAdmin(params?: any): Observable<IApiReceiveResponse<IArtist>> {
        return this.apiService.get<IApiReceiveResponse<IArtist>>('artists/admin/receive/all', {params}, true);
    }

    getArtist(artistId: string): Observable<IArtist> {
        return this.apiService.get<any>(`artists/receive/${artistId}`, {}, true)
            .pipe(map(artist => this.sharedService.formatArtist(artist)));
    }

    updateArtist(artistId: string, data: any): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artists/update/${artistId}`, data, {}, true, true);
    }

    deleteArtistProfile(artistId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`artists/delete/${artistId}`, {}, true);
    }

    updateArtistProfile(data: IArtist): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artists/update/profile`, data, {}, true, true);
    }

    updateUserProfile(userId: string, data: IUser): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artists/update/user-profile/${userId}`, data, {}, true, true);
    }

    getSpecializations(): Observable<any[]> {
        return this.apiService.get<any[]>('artists/specializations/receive/all', {}, true);
    }
}
