import {Directive, HostListener, OnInit} from '@angular/core';
import {SharedService} from '../services';

@Directive({
    selector: '[appPictureLoad]',
})
export class PictureLoadDirective implements OnInit {
    @HostListener('load')
    onLoad() {
        this.sharedService.emitLoaderChange(false);
    }

    @HostListener('error')
    onError() {
        this.sharedService.emitLoaderChange(false);
    }

    constructor(private sharedService: SharedService) {
    }

    ngOnInit(): void {
        this.sharedService.emitLoaderChange(true);
    }
}
