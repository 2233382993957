export default {
    email_required: 'Email is required',
    email_invalid: 'Email is invalid',
    email_unique: 'Email already registered',
    email_unverified: 'User is not verified',
    email_not_found: 'Email is not registered',
    password_required: 'Password is required',
    email_or_password_incorrect: 'Email or password is incorrect',
    first_name_required: 'First name is required',
    first_name_min_length: '2 characters minimum',
    first_name_max_length: '40 characters maximum',
    first_name_invalid: 'First name is invalid',
    last_name_required: 'Last name is required',
    last_name_min_length: '2 characters minimum',
    last_name_max_length: '40 characters maximum',
    last_name_invalid: 'Last name is invalid',
    missing_lowercase: 'Missing lowercase character',
    missing_uppercase: 'Missing uppercase character',
    missing_numeric: 'Missing numeric character',
    missing_non_alphanumeric: 'Missing non alphanumeric character',
    password_min_length: '8 characters minimum',
    password_max_length: '24 characters maximum',
    confirm_password_required: 'Confirm password is required',
    confirm_password_mismatch: 'Passwords is mismatch',
    phone_required: 'Phone is required',
    phone_invalid: 'Phone is invalid',
    phone_unique: 'Phone already registered',
    gender_required: 'Gender is required',
    address_required: 'Address is required',
    city_required: 'City is required',
    state_required: 'State is required',
    country_required: 'Country is required',
    pseudonym_required: 'Pseudonym is required',
    date_of_birth_required: 'Date of birth is required',
    about_required: 'About is required',
    from_the_artist_required: 'From the artist is required',
    education_name_required: 'Name is required',
    education_from_year_required: 'From year is required',
    education_to_year_required: 'To year is required',
    education_specialization_required: 'Specialization is required',
    exhibition_name_required: 'Name is required',
    exhibition_date_required: 'Date is required',
    exhibition_type_required: 'Type is required',
    profile_picture_required: 'Profile picture is required',
    about_min_length: '20 characters minimum',
    from_the_artist_min_length: '20 characters minimum',
    pseudonym_min_length: '2 characters minimum',
    pseudonym_max_length: '40 characters maximum',
    artwork_pictures_required: 'Pictures required',
    artwork_pictures_min: '1 picture minimum',
    artwork_pictures_max: '5 picture maximum',
    artwork_name_required: 'Name is required',
    artwork_name_min_length: '2 characters minimum',
    artwork_name_max_length: '40 characters maximum',
    artwork_year_required: 'Year is required',
    artwork_height_required: 'Height is required',
    artwork_width_required: 'Width is required',
    artwork_depth_required: 'Depth is required',
    artwork_weight_required: 'Weight is required',
    artwork_base_price_required: 'Price is required',
    artwork_category_required: 'Category is required',
    artwork_size_required: 'Size is required',
    artwork_style_required: 'Styles is required',
    artwork_subject_required: 'Subjects is required',
    artwork_medium_required: 'Mediums is required',
    artwork_material_required: 'Materials is required',
    artwork_color_required: 'Colors is required',
    artwork_style_max: 'Maximum 3 style',
    artwork_subject_max: 'Maximum 3 subject',
    artwork_medium_max: 'Maximum 3 medium',
    artwork_material_max: 'Maximum 3 material',
    artwork_color_max: 'Maximum 3 color',
    current_password_required: 'Current password is required',
    postal_code_required: 'Postal code is required',
    second_address_required: 'Address 2 is required',
    reset_password_email_sent: 'Reset password email has been sent',
    reset_password_link_invalid: 'Reset password link is invalid',
    password_updated: 'Password successfully updated',
    category_name_required: 'Category name is required',
    category_min_length: '2 characters minimum',
    category_max_length: '40 characters maximum',
    category_name_unique: 'Category name already registered',
    style_name_required: 'Style name is required',
    style_min_length: '2 characters minimum',
    style_max_length: '40 characters maximum',
    style_name_unique: 'Style name already registered',
    subject_name_required: 'Subject name is required',
    subject_min_length: '2 characters minimum',
    subject_max_length: '40 characters maximum',
    subject_name_unique: 'Subject name already registered',
    medium_name_required: 'Medium name is required',
    medium_min_length: '2 characters minimum',
    medium_max_length: '40 characters maximum',
    medium_name_unique: 'Medium name already registered',
    material_name_required: 'Material name is required',
    material_min_length: '2 characters minimum',
    material_max_length: '40 characters maximum',
    material_name_unique: 'Material name already registered',
    color_code_required: 'Color code is required',
    color_code_invalid: 'Color code is invalid',
    color_code_unique: 'Color code already registered',
    category_used: 'You can\'t delete this category',
    style_used: 'You can\'t delete this style',
    subject_used: 'You can\'t delete this subject',
    medium_used: 'You can\'t delete this medium',
    material_used: 'You can\'t delete this material',
    color_used: 'You can\'t delete this color',
    style_required: 'Style is required',
    subject_required: 'Subject is required',
    medium_required: 'Medium is required',
    material_required: 'Material is required',
    artwork_artist_required: 'Artist is required',
    category_added: 'Category successfully added',
    category_updated: 'Category successfully updated',
    category_deleted: 'Category successfully deleted',
    style_added: 'Style successfully added',
    style_updated: 'Style successfully updated',
    style_deleted: 'Style successfully deleted',
    subject_added: 'Subject successfully added',
    subject_updated: 'Subject successfully updated',
    subject_deleted: 'Subject successfully deleted',
    medium_added: 'Medium successfully added',
    medium_updated: 'Medium successfully updated',
    medium_deleted: 'Medium successfully deleted',
    material_added: 'Material successfully added',
    material_updated: 'Material successfully updated',
    material_deleted: 'Material successfully deleted',
    color_added: 'Color successfully added',
    color_updated: 'Color successfully updated',
    color_deleted: 'Color successfully deleted',
    category_style_added: 'Style ordering successfully added',
    category_style_updated: 'Style ordering successfully updated',
    category_style_deleted: 'Style ordering successfully deleted',
    category_style_used: 'You can\'t delete this style ordering',
    category_subject_added: 'Subject ordering successfully added',
    category_subject_updated: 'Subject ordering successfully updated',
    category_subject_deleted: 'Subject ordering successfully deleted',
    category_subject_used: 'You can\'t delete this subject ordering',
    category_medium_added: 'Medium ordering successfully added',
    category_medium_updated: 'Medium ordering successfully updated',
    category_medium_deleted: 'Medium ordering successfully deleted',
    category_medium_used: 'You can\'t delete this medium ordering',
    category_material_added: 'Material ordering successfully added',
    category_material_updated: 'Material ordering successfully updated',
    category_material_deleted: 'Material ordering successfully deleted',
    category_material_used: 'You can\'t delete this material ordering'
};
