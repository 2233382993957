import {Component, OnInit} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {IAdmin} from '../../interfaces';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
    admin: IAdmin;
    sidenavActive = true;
    tabletMediaMatcher: MediaQueryList;

    constructor(private mediaMatcher: MediaMatcher,
                private route: ActivatedRoute) {
        this.tabletMediaMatcher = mediaMatcher.matchMedia('(max-width: 1160px)');
        this.sidenavActive = !this.tabletMediaMatcher.matches;
        this.tabletMediaMatcher.onchange = () => {
            this.sidenavActive = !this.tabletMediaMatcher.matches;
        };
    }

    ngOnInit(): void {
        this.admin = this.route.snapshot.data.admin;
    }
}
