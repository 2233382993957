import {Injectable} from '@angular/core';
import {SharedService} from './shared.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {IApiReceiveResponse, IApiResponse, IArtwork} from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class ArtworkService {

    constructor(private apiService: ApiService,
                private sharedService: SharedService) {
    }

    getArtworksForAdmin(params: any): Observable<IApiReceiveResponse<IArtwork>> {
        return this.apiService.get<IApiReceiveResponse<IArtwork>>('artworks/admin/receive/all', {params}, true);
    }

    getArtworkForAdmin(artworkId: string): Observable<IArtwork> {
        return this.apiService.get<IArtwork>(`artworks/admin/receive/${artworkId}`, {}, true)
        .pipe(map(artwork => this.sharedService.formatArtwork(artwork)));
    }

    updateArtwork(artworkId: string, data: any): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artworks/update/${artworkId}`, data, {}, true, true);
    }

    deleteArtwork(artworkId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`artworks/delete/${artworkId}`, {}, true, true);
    }
}
