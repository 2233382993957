import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IMedium} from '../interfaces';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MediumService {

    constructor(private apiService: ApiService) {
    }

    getMediums(params: any): Observable<IApiReceiveResponse<IMedium>> {
        return this.apiService.get<IApiReceiveResponse<IMedium>>('mediums/receive/all', {params}, true).pipe(
            map(({results, count}) => {
                if (params.categoryId) {
                    results = results.map((medium) => {
                        if (medium.categoryMediums && medium.categoryMediums.length) {
                            medium.categoryMediumId = medium.categoryMediums[0].categoryMediumId;
                        }
                        delete medium.categoryMediums;
                        return medium;
                    });
                }

                return {results, count};
            })
        );
    }

    getMedium(mediumId: string): Observable<IMedium> {
        return this.apiService.get<IMedium>(`mediums/receive/${mediumId}`, {}, true);
    }

    addMedium(medium: IMedium): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('mediums/add', medium, {}, true, true);
    }

    updateMedium(mediumId: string, medium: IMedium): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`mediums/update/${mediumId}`, medium, {}, true, true);
    }

    deleteMedium(mediumId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`mediums/delete/${mediumId}`, {}, true, true);
    }

    getCategoryMediums(params: any): Observable<IApiReceiveResponse<IMedium>> {
        return this.apiService.get<IApiReceiveResponse<IMedium>>('mediums/category-medium/receive/all', {params}, true)
            .pipe(map(({results, count}) => {
                results = results.map((medium) => {
                    medium.name = medium.medium.name;
                    delete medium.medium;
                    return medium;
                });
                return {results, count};
            }));
    }

    getCategoryMedium(categoryMediumId: string): Observable<IMedium> {
        return this.apiService.get<IMedium>(`mediums/category-medium/receive/${categoryMediumId}`, {}, true)
    }

    addCategoryMedium(categoryId: string, mediumId: string): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`mediums/category-medium/add/${categoryId}/${mediumId}`, {}, {}, true, true);
    }

    updateCategoryMedium(categoryMediumId: string, order: number): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`mediums/category-medium/update/${categoryMediumId}`, {order}, {}, true, true);
    }

    deleteCategoryMedium(categoryMediumId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`mediums/category-medium/delete/${categoryMediumId}`, {}, true, true);
    }
}
