import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService, ValidatorService} from '../../services';

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
    updatePasswordForm: FormGroup;
    currentPassword: FormControl;
    password: FormControl;
    confirmPassword: FormControl;
    hide = true;

    constructor(private adminService: AdminService,
                private validatorService: ValidatorService,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.createFormControls();
    }

    updatePassword() {
        if (!this.updatePasswordForm.valid) {
            return;
        }

        const currentPassword = this.currentPassword.value;
        const password = this.password.value;
        const confirmPassword = this.confirmPassword.value;

        this.adminService
            .updatePassword(currentPassword, password, confirmPassword)
            .subscribe(() => {});
    }

    private createFormControls() {
        this.currentPassword = new FormControl('', [
            Validators.required
        ]);
        this.password = new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(24),
            this.validatorService.password
        ]);
        this.confirmPassword = new FormControl('', [
            Validators.required
        ]);
        this.createFormGroup();
    }

    private createFormGroup() {
        this.updatePasswordForm = this.fb.group({
            currentPassword: this.currentPassword,
            password: this.password,
            confirmPassword: this.confirmPassword
        }, { validator: this.validatorService.confirmPassword });
    }

}
