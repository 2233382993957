<form id="updateProfileForm" name="updateProfileForm"  [formGroup]="updateProfileForm" (submit)="updateProfile()" novalidate>
    <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="10px">
        <mat-form-field>
            <input type="text" matInput placeholder="First Name" formControlName="firstName" required>
            <mat-error *ngIf="firstName.hasError('required')">{{ 'first_name_required' | text }}</mat-error>
            <mat-error *ngIf="firstName.hasError('minlength')">{{ 'first_name_min_length' | text }}</mat-error>
            <mat-error *ngIf="firstName.hasError('maxlength')">{{ 'first_name_max_length' | text }}</mat-error>
            <mat-error *ngIf="!firstName.hasError('minlength') && firstName.hasError('invalid') ">
                {{ 'first_name_invalid' | text }}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="text" matInput placeholder="Last Name" formControlName="lastName" required>
            <mat-error *ngIf="lastName.hasError('required')">{{ 'last_name_required' | text }}</mat-error>
            <mat-error *ngIf="lastName.hasError('minlength')">{{ 'last_name_min_length' | text }}</mat-error>
            <mat-error *ngIf="lastName.hasError('maxlength')">{{ 'last_name_max_length' | text }}</mat-error>
            <mat-error *ngIf="!lastName.hasError('minlength') && lastName.hasError('invalid')">
                {{ 'last_name_invalid' | text }}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input type="email" matInput placeholder="Email" formControlName="email" readonly required>
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="weightUnit" placeholder="Weight Unit">
                <mat-option value="kg">Kg</mat-option>
                <mat-option value="pound">Pound</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select formControlName="dimensionUnit" placeholder="Dimension Unit">
                <mat-option value="inch">Inch</mat-option>
                <mat-option value="cm">Cm</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
        <span fxFlex></span>
        <div class="button-underline-right">
            <button class="submit-button button button-1 text-green" type="submit"
                    fxFlexAlign="end end">Save
            </button>
        </div>
    </div>
</form>
