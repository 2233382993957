import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IAdmin, IApiReceiveResponse, IApiResponse, IInitialPermission} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private apiService: ApiService) {
    }

    getAdmins(params: any): Observable<IApiReceiveResponse<IAdmin>> {
        return this.apiService.get<IApiReceiveResponse<IAdmin>>('admins/admin/receive/all', {params}, true);
    }

    getProfile(): Observable<IAdmin> {
        return this.apiService.get<IAdmin>('admins/admin/profile', {}, true);
    }

    getInitialRoles(): Observable<string[]> {
        return this.apiService.get<string[]>('admins/initial/roles', {}, true);
    }

    getInitialPermissions(): Observable<IInitialPermission[]> {
        return this.apiService.get<IInitialPermission[]>('admins/initial/permissions', {}, true);
    }

    invite(email: string, roleName: string, permissions: any): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('admins/admin/invite', {email, roleName, permissions}, {}, true, true);
    }

    updateProfile(data: IAdmin): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>('admins/admin/update/profile', data, {}, true, true);
    }

    updatePassword(currentPassword: string, password: string, confirmPassword: string): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>('admins/admin/update/password', {
            currentPassword,
            password,
            confirmPassword
        }, {}, true, true);
    }
}
