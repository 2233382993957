import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IApiResponse, ICompany} from '../interfaces';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private apiService: ApiService) {
    }

    getCompany(): Observable<ICompany> {
        return this.apiService.get<ICompany>('company/receive', {}, true);
    }

    updateCompany(values: ICompany): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>('company/update', values, {}, true, true);
    }
}
