import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IApiReceiveResponse, IApiResponse, ISubject} from '../interfaces';
import {IDonationMade} from '../interfaces/donation-made';

@Injectable({
    providedIn: 'root'
})
export class DonationMadeService {
    constructor(private apiService: ApiService) {
    }

    getDonationMades(params: any): Observable<IApiReceiveResponse<IDonationMade>> {
        return this.apiService.get<IApiReceiveResponse<IDonationMade>>('donation-made/receive/all', {params}, true);
    }

    updateDonationMade(donationMadeId:string, donationMade: IDonationMade): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`donation-made/update/${donationMadeId}`, donationMade, {}, true, true);
    }

    addDonationMade(donationMade: IDonationMade): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`donation-made/add`, donationMade, {}, true, true);
    }

    getDonationMade(donationMadeId: string): Observable<IDonationMade> {
        return this.apiService.get<IDonationMade>(`donation-made/receive/${donationMadeId}`, {}, true);
    }

    deleteDonationMade(donationMadeId: string): Observable<IApiResponse> {
        return this.apiService.delete<IApiResponse>(`donation-made/delete/${donationMadeId}`, {}, true, true);
    }
}
