import {Component, Input} from '@angular/core';
import {IAddressBookItem} from '../../interfaces';

@Component({
    selector: 'app-address-book-preview',
    templateUrl: './address-book-preview.component.html',
    styleUrls: ['./address-book-preview.component.scss']
})
export class AddressBookPreviewComponent {
    @Input() addressBookItem: IAddressBookItem;
    @Input() showDefault = true;
    constructor() {
    }
}
