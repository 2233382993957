<div fxLayout="row" fxLayout.lt-sm="column">
    <div fxFlex fxLayout="column" fxFlexOrder.lt-sm="2" fxLayoutGap="20px">
        <div fxLayout="column">
            <span class="subtitle subtitle-2 text-strong">NAME</span>
            <span class="body-1 text-gray">{{admin.firstName | titlecase}} {{admin.lastName | titlecase}}</span>
        </div>
        <div fxLayout="column">
            <span class="subtitle subtitle-2 text-strong">Email address</span>
            <span class="body-1 text-gray">{{admin.email}}</span>
        </div>
        <div fxLayout="column">
            <span class="subtitle subtitle-2 text-strong">Password</span>
            <span class="body-1 text-gray">**********</span>
        </div>
        <div fxLayout="column">
            <span class="subtitle subtitle-2 text-strong">Dimension Unit</span>
            <span class="body-1 text-gray">{{admin.dimensionUnit | titlecase}}</span>
        </div>
        <div fxLayout="column">
            <span class="subtitle subtitle-2 text-strong">Weight Unit</span>
            <span class="body-1 text-gray">{{admin.weightUnit | titlecase}}</span>
        </div>
    </div>
    <div fxFlex fxLayout="column" fxFlexOrder.lt-sm="1" fxLayoutAlign.gt-sm="start end" class="profile-picture">
        <img [src]="(admin.pictureSmall | picture) ? (admin.pictureSmall | picture) : '/assets/images/default-avatar.png'">
    </div>
</div>
