import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {IApiReceiveResponse, IApiResponse, IArtist} from '../interfaces';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ArtistReviewService {

    constructor(private apiService: ApiService) {
    }

    getArtistsForAdmin(params: any): Observable<IApiReceiveResponse<IArtist>> {
        return this.apiService.get<IApiReceiveResponse<IArtist>>('artist-review/admin/receive/all', {params}, true);
    }

    getArtistsForJury(params: any): Observable<IApiReceiveResponse<IArtist>> {
        return this.apiService.get<IApiReceiveResponse<IArtist>>('artist-review/jury/receive/all', {params}, true);
    }

    getArtistForAdmin(artistId: string): Observable<IArtist> {
        return this.apiService.get<IArtist>(`artist-review/admin/receive/${artistId}`, {}, true);
    }

    getArtistForJury(artistId: string): Observable<IArtist> {
        return this.apiService.get<IArtist>(`artist-review/jury/receive/${artistId}`, {}, true).pipe(
            map((artist) => {
                artist.artworks = artist.artworks.map((artwork) => {
                    artwork.styles = artwork.artworkStyles.map(artworkStyle => artworkStyle.style);
                    artwork.mediums = artwork.artworkMediums.map(artworkMedium => artworkMedium.medium);
                    artwork.materials = artwork.artworkMaterials.map(artworkMaterial => artworkMaterial.material);
                    delete artwork.artworkStyles;
                    delete artwork.artworkMediums;
                    delete artwork.artworkMaterials;
                    return artwork;
                });
                return artist;
            })
        );
    }

    adminReview(artistId: string, type: string, data?: string): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artist-review/${type}/admin/${artistId}`, data, {}, true, true);
    }

    juryReview(artistId: string, data): Observable<IApiResponse> {
        return this.apiService.put<IApiResponse>(`artist-review/jury/${artistId}`, data, {}, true, true);
    }
}
