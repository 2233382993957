import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseComponent} from './shared-module/components/base/base.component';
import {AdminResolverService} from './shared-module/resolvers';
import {AuthGuard} from './shared-module/guards';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth-module/auth.module').then(m => m.AuthModule),
    },
    {
        path: '',
        component: BaseComponent,
        resolve: {
            admin: AdminResolverService
        },
        children: [
            {
                path: 'home',
                loadChildren: () => import('./home-module/home.module').then(m => m.HomeModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard-module/dashboard.module').then(m => m.DashboardModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'admins',
                loadChildren: () => import('./admin-module/admin.module').then(m => m.AdminModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'artists',
                loadChildren: () => import('./artist-module/artist.module').then(m => m.ArtistModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'artist-review',
                loadChildren: () => import('./artist-review-module/artist-review.module').then(m => m.ArtistReviewModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'artwork-review',
                loadChildren: () => import('./artwork-review-module/artwork-review.module').then(m => m.ArtworkReviewModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'artworks',
                loadChildren: () => import('./artworks-module/artworks.module').then(m => m.ArtworksModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'categories',
                loadChildren: () => import('./categories-module/categories.module').then(m => m.CategoriesModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'shipping',
                loadChildren: () => import('./shipping-module/shipping.module').then(m => m.ShippingModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'customers',
                loadChildren: () => import('./customer-module/customer.module').then(m => m.CustomerModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings-module/settings.module').then(m => m.SettingsModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'promo-codes',
                loadChildren: () => import('./promo-codes-module/promo-codes.module').then(m => m.PromoCodesModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'orders',
                loadChildren: () => import('./orders-module/orders.module').then(m => m.OrdersModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'donations',
                loadChildren: () => import('./donations-module/donations.module').then(m => m.DonationsModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'gift-cards',
                loadChildren: () => import('./gift-cards-module/gift-cards.module').then(m => m.GiftCardsModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'offers',
                loadChildren: () => import('./offer-module/offer.module').then(m => m.OfferModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'our-team',
                loadChildren: () => import('./our-team-module/our-team.module').then(m => m.OurTeamModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'art-center',
                loadChildren: () => import('./art-center-module/art-center.module').then(m => m.ArtCenterModule),
                canLoad: [AuthGuard]
            },
            {
                path: 'filtered-links',
                loadChildren: () => import('./filtered-link-module/filtered-link.module').then(m => m.FilteredLinkModule),
                canLoad: [AuthGuard]
            },
        ]
    },
    {
        path: 'print',
        loadChildren: () => import('./print-module/print.module').then(m => m.PrintModule),
        canLoad: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
