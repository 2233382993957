import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService, ValidatorService} from '../../services';
import {IAdmin} from '../../interfaces';

@Component({
    selector: 'app-update-profile',
    templateUrl: './update-profile.component.html',
    styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
    @Input() admin: IAdmin;
    @Output() adminChange = new EventEmitter<void>();
    updateProfileForm: FormGroup;
    email: FormControl;
    firstName: FormControl;
    lastName: FormControl;
    weightUnit: FormControl;
    dimensionUnit: FormControl;

    constructor(private adminService: AdminService,
                private fb: FormBuilder,
                private validatorService: ValidatorService) {
    }

    ngOnInit() {
        this.createFormControls();
    }

    updateProfile(): void {
        if (!this.updateProfileForm.valid) {
            return;
        }

        const values: any = {
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            weightUnit: this.weightUnit.value,
            dimensionUnit: this.dimensionUnit.value,
        };

        this.adminService
            .updateProfile(values)
            .subscribe(() => {
                this.adminChange.emit();
            });
    }

    private createFormControls(): void {
        this.email = new FormControl(this.admin.email, [Validators.required, Validators.email]);
        this.firstName = new FormControl(this.admin.firstName, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(40),
            this.validatorService.onlyAlpha
        ]);
        this.lastName = new FormControl(this.admin.lastName, [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(40),
            this.validatorService.onlyAlpha
        ]);
        this.weightUnit = new FormControl(this.admin.weightUnit || '', [
            Validators.required
        ]);
        this.dimensionUnit = new FormControl(this.admin.dimensionUnit || '', [
            Validators.required
        ]);

        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.updateProfileForm = this.fb.group({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            weightUnit: this.weightUnit,
            dimensionUnit: this.dimensionUnit,
        });
    }
}
