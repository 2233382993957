import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IAdmin, IArtist, IArtwork} from '../../interfaces';
import {FormControl} from '@angular/forms';
import {ArtworkService} from '../../services';

@Component({
    selector: 'app-artwork-preview',
    templateUrl: './artwork-preview.component.html',
    styleUrls: ['./artwork-preview.component.scss']
})
export class ArtworkPreviewComponent implements OnInit {
    @Input() artwork: IArtwork;
    @Input() artist: IArtist;
    @Input() admin: IAdmin;
    @Input() showChangePrice: boolean;
    @Output() artworkChanged = new EventEmitter<void>();
    selectedPrice: FormControl;

    constructor(private artworkService: ArtworkService) {
        this.selectedPrice = new FormControl('');
    }

    ngOnInit() {
        this.selectedPrice.setValue(`${this.artwork.increasedPercent}-${this.artwork.allowedDiscount}`);
    }

    updateArtwork(): void {
        if (!this.selectedPrice.value) {
            return;
        }

        const [increasedPercent, allowedDiscount] = this.selectedPrice.value.split('-');

        const data: any = {
            increasedPercent: Number(increasedPercent),
            allowedDiscount: Number(allowedDiscount)
        };

        this.artworkService
            .updateArtwork(this.artwork.artworkId, data)
            .subscribe(() => this.artworkChanged.emit());
    }
}
